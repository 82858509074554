.p-greeting {
  margin-bottom: 80px;
  @include sp {
    margin-bottom: 60px;
  }
  &_cont {
    padding: 40px;
    @include sp {
      padding: 25px;
    }
  }

  &_wrap {
    display: flex;
    @include tab {
      display: block;
    }
  }

  &_pic {
    width: 32%;
    margin-right: 4%;
    @include tab {
      margin: 0 auto 30px;
    }
    @include sp {
      width: 100%;
      text-align: center;
    }
  }

  &_textBox {
    width: 64%;
    @include tab {
      width: 100%;
    }
  }

  &_text {
    font-size: 18px;
    line-height: 1.7;
    margin-bottom: 30px;
    @include sp {
      font-size: 14px;
      margin-bottom: 20px;
    }
  }

  &_ceo {
    font-size: 22px;
    font-weight: 500;
    @include sp {
      font-size: 18px;
    }
  }
}