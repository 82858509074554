.p-company {
  &_inner {
    padding: 80px 0;
    @include sp {
      padding: 60px 0;
    }

    &.gray {
      background-color: $gray;
    }
  }

  &_cont {
    padding: 60px 0;

    @include sp {
      padding: 30px 0;
    }
  }

  &_text {
    color: $blue;
    font-size: 28px;
    @include tab {
      font-size: 22px;
    }
    @include sp {
      font-size: 16px;
    }
  }

  &_vision {
    padding: 80px 0;
    margin-bottom: 80px;
    background: url('../img/company/bg_index01.jpg') no-repeat center;
    background-size: cover;
    @include sp {
      padding: 40px 0;
      margin-bottom: 60px;
    }

    &--cont {
      &:first-child {
        @include sp {
          margin-bottom: 40px;
        }
      }
    }

    &--box {
      background-color: rgba($white, 0.8);
    }

    &--text {
      color: $blue;
      font-size: 28px;
      font-weight: bold;
      line-height: 1.5;
      text-align: center;
      padding: 40px 0;
      @media screen and (max-width: 1150px) and (min-width: 901px) {
        font-size: 22px;
      }
      @include tab {
        font-size: 18px;
      }
      @include sp {
        font-size: 16px;
        padding: 30px 0;
      }
    }
  }

  &_plan {
    margin-bottom: 80px;
    @include sp {
      margin-bottom: 60px;
    }
    &--wrap {
      margin-bottom: 40px;
      @include sp {
        margin-bottom: 30px;
      }
    }
    &--box {
      background-color: rgba($gray, 0.3);

      &:first-child {
        @include sp {
          margin-bottom: 30px;
        }
      }
    }

    &--ttl {
      color: $blue;
      font-size: 18px;
      font-weight: bold;
      padding: 20px 0;
      background-color: $gray;
    }

    &--textBox {
      height: calc( 100% - 68px);
      padding: 40px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      @include sp {
        padding: 30px 0;
      }
    }

    &--read {
      color: $blue;
      font-size: 22px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 5px;
      @media screen and (max-width: 1150px) {
        font-size: 18px;
      }
    }

    &--text {
      font-size: 22px;
      font-weight: bold;
      font-feature-settings: 'palt';
      text-align: center;
      @media screen and (max-width: 1150px) {
        font-size: 18px;
      }

      .line {
        background:linear-gradient(transparent 60%, #E8ECF3 60%);
      }

      .orange {
        color: #FF9140;
      }
    }
  }

  &_policy {
    max-width: 793px;
    width: 100%;
    margin: 0 auto;
    padding: 40px 0;
    @include sp {
      padding: 30px 0;
    }

    &--ttl {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 30px;
      @include sp {
        font-size: 18px;
        margin-bottom: 20px;
      }
    }

    &--wrap {
      display: flex;
      justify-content: center;
    }

    &--list {
      font-size: 20px;
      line-height: 1.8;
      @include sp {
        font-size: 16px;
      }

      li {
        text-indent: -1em;
        padding-left: 1em;
        font-feature-settings: 'palt';
        letter-spacing: 1px;
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }
  }

  &Concept {
    &_inner {
      padding: 100px 0 80px;
      @include sp {
        padding: 60px 0;
      }
    }

    &_ttl {
      font-size: 22px;
      font-weight: bold;
      @include sp {
        font-size: 18px;
      }
    }

    &_img {
      max-width: 793px;
      width: 100%;
      margin: 40px auto 0;
      @include sp {
        margin-top: 30px;
      }
    }

    &_box {
      max-width: 793px;
      width: 100%;
      margin: 60px auto 0;
      padding: 40px 5% 25px;
      background-color: #EFF1F3;
      @include sp {
        margin-top: 40px;
        padding: 30px 5% 20px;
      }

      &--ttl {
        font-size: 22px;
        font-weight: bold;
        @include sp {
          font-size: 18px;
        }
      }

      &List {
        margin-top: 10px;
        &--item {
          font-size: 18px;
          position: relative;
          padding: 25px 4.2%;
          border-bottom: 1px solid $blue;
          @include sp {
            font-size: 14px;
            padding: 15px 4%;
          }
        }

        &--text {
          padding-left: 50px;
          position: relative;
          @include sp {
            padding-left: 30px;
          }

          span {
            color: $blue;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }
}