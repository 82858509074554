.c-mv {
  margin-bottom: 80px;
  position: relative;
  @include sp {
    margin-bottom: 60px;
  }
  &_inner {
    width: 100%;
    min-height: 300px;
    padding: 60px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include sp {
      min-height: 225px;
      padding: 40px 20px;
    }
  }

  &_heading {
    color: $white;

    h2 {
      font-size: 30px;
      @include sp {
        font-size: 22px;
      }
    }
  }

  &_sub {
    font-size: 10px;
    margin-top: 15px;
    @include sp {
      margin-bottom: 10px;
    }
  }

  &_text {
    font-size: 16px;
    margin-top: 30px;
    @include sp {
      font-size: 14px;
      margin-top: 20px;
    }
  }

  &_breadcrumb {
    display: flex;
    position: absolute;
    top: 25px;
    left: 30px;
    @include sp {
      display: none;
    }
    a {
      color: $white;
      font-size: 12px;
    }

    li {
      &:not(:last-child) {
        padding-right: 15px;
        margin-right: 15px;
        position: relative;
        &:after {
          content: '';
          width: 6px;
          height: 6px;
          border: 0px;
          border-top: solid 2px $white;
          border-right: solid 2px $white;
          -ms-transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          position: absolute;
          top: 50%;
          right: 0;
          margin-top: -2px;
        }
      }
    }

    .current {
      a {
        text-decoration: underline;
      }
    }
  }

  &.network {
    .c-mv_inner {
      background: url('../img/network/mv_bg01.jpg') no-repeat center;
      background-size: cover;
    }
  }

  &.company {
    .c-mv_inner {
      background: url('../img/company/mv_bg01.jpg') no-repeat center;
      background-size: cover;
    }
  }

  &.news,
  &.privacy,
  &.contact {
    .c-mv_inner {
      height: 200px;
      background: url('../img/news/mv_bg01.jpg') no-repeat center;
      background-size: cover;
    }
  }

  &.news {
    .c-mv_inner {
      min-height: 200px;
      height: auto;
      padding: 60px 0;
      @include sp {
        padding: 40px 20px;
      }
    }

    h2 {
      @include sp {
        font-size: 18px;
      }
    }
  }

  &.strengths {
    .c-mv_inner {
      background: url('../img/strengths/mv_bg01.jpg') no-repeat center;
      background-size: cover;
    }
  }

  &.works {
    .c-mv_inner {
      background: url('../img/works/mv_bg01.jpg') no-repeat center;
      background-size: cover;
    }
  }

  &.service {
    .c-mv_inner {
      background: url('../img/service/mv_bg01.jpg') no-repeat center;
      background-size: cover;
    }
  }

  &.eclogistics {
    .c-mv_inner {
      background: url('../img/service/eclogistics_mv01.jpg') no-repeat center;
      background-size: cover;
    }
  }

  &.electronics {
    .c-mv_inner {
      background: url('../img/service/eclogistics_mv01.jpg') no-repeat center;
      background-size: cover;
    }
  }

  &.material {
    .c-mv_inner {
      background: url('../img/service/material/mv_bg01.jpg') no-repeat center;
      background-size: cover;
    }
  }

  &.funiture {
    .c-mv_inner {
      background: url('../img/service/funiture/mv_bg01.jpg') no-repeat center;
      background-size: cover;
    }
  }

  &.partnership {
    .c-mv_inner {
      background: url('../img/service/partnership/mv_bg01.jpg') no-repeat center;
      background-size: cover;
    }
  }

  &.logisticsequipment {
    .c-mv_inner {
      background: url('../img/service/eclogistics_mv01.jpg') no-repeat center;
      background-size: cover;
    }
  }

  &.system {
    .c-mv_inner {
      background: url('../img/system/mv_bg01.jpg') no-repeat center;
      background-size: cover;
    } 
  }

  &.wms {
    .c-mv_inner {
      background: url('../img/system/wms_mv01.jpg') no-repeat center;
      background-size: cover;
    } 
  }

  &.tms {
    .c-mv_inner {
      background: url('../img/system/tms_mv01.jpg') no-repeat center;
      background-size: cover;
    } 
  }
}