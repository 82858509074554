.p-system {

  &_inner {
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
  }

  &_wrap {
    display: flex;
    @include sp {
      display: block;
    }
  }

  &_cont {
    width: 50%;
    padding: 80px 0;
    position: relative;
    overflow: hidden;
    @include sp {
      width: 100%;
      padding: 40px 0;
    }

    // &:before {
    //   content: '';
    //   width: 100%;
    //   height: 100%;
    //   background-color: rgba(0,0,0,0.5);
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   z-index: 2;
    // }
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      background: url('../img/system_pic01.png') no-repeat center center;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      transition: 0.3s;
    }

    &.hub:after {
      background: url('../img/hub_pic01.png') no-repeat center center;
      background-size: cover;
    }

    &:hover {
      &:after {
        transform: scale(1.1);
      }

      .c-btn_cmn {
        background-position: -68px;
        opacity: 1;
  
        &:after {
          width: 100%;
        }
      }
    }
  }

  &_textBox {
    position: relative;
    z-index: 3;
  }

  &_text {
    color: $white;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 25px;
    @include sp {
      font-size: 14px;
    }
  }

  &_btn {
    max-width: 365px;
    width: 94%;
    margin: 0 auto;
  }
}