.p-link {
  &_inner {
    padding: 80px 0;
    background-color: $gray;
    @include sp {
      padding: 40px 0;
    }
  }

  &_list {
    display: flex;
    justify-content: space-between;
    @include sp {
      display: block;
    }

    &--item {
      width: 31.7%;
      @include sp {
        width: 100%;
      }
      &:not(:last-child) {
        margin-bottom: 20px;
      }

      a {
        color: $white;
        font-size: 20px;
        font-weight: 500;
        padding: 140px 15px 10px;
        display: block;
        position: relative;
        overflow: hidden;

        &:before {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          transition: 0.3s;
        }
      }

      span {
        position: relative;
      }

      &:hover a {
        opacity: 1;
        &:before {
          transform: scale(1.1);
        }
      }

      &.company a:before {
        background: url('../img/company_pic01.jpg') no-repeat center;
        background-size: cover;
      }

      &.recruit a:before {
        background: url('../img/recruit_pic01.jpg') no-repeat center;
        background-size: cover;
      }

      &.group a:before {
        background: url('../img/group_pic01.jpg') no-repeat center;
        background-size: cover;
      }
    }
  }
}