.u {
  &-mb {
    &_10 {
      margin-bottom: 10px !important;
    }
    &_15 {
      margin-bottom: 15px !important;
      @include sp {
        margin-bottom: 10px !important;
      }
    }

    &_20 {
      margin-bottom: 20px !important;
      @include sp {
        margin-bottom: 10px !important;
      }
    }
    &_30 {
      margin-bottom: 30px !important;
      @include sp {
        margin-bottom: 20px !important;
      }
    }
    &_40 {
      margin-bottom: 40px !important;
      @include sp {
        margin-bottom: 25px !important;
      }
    }
    &_45 {
      margin-bottom: 45px !important;
    }
  }

  &-mt {
    &_10 {
      margin-top: 10px !important;
    }
  }
}