.u-indent {
  &_0 {
    text-indent: 0 !important;
    padding-left: 0 !important;
  }
  &_0-5 {
    text-indent: -0.5em !important;
    padding-left: 0.5em !important; 
  }
  &_1 {
    text-indent: -1em !important;
    padding-left: 1em !important;
  }
  &_1-3 {
    text-indent: -1.3em !important;
    padding-left: 1.3em !important;
  }
  &_1-8 {
    text-indent: -1.8em;
    padding-left: 1.8em;
    display: inline-block;
  }
}