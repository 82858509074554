.p-news {
  &_inner {
    padding: 80px 0 155px;
    @include tab {
      padding: 80px 0;
    }
    @include sp {
      padding: 40px 0 60px;
    }
  }

  &_list {
    margin-bottom: 40px;
    @include sp {
      margin-bottom: 30px;
    }
    &--item {
      border-bottom: 1px solid #a7b9d5;
      &:first-child {
        border-top: 1px solid #a7b9d5;
      }

      a {
        padding: 25px 15px;
        transition: 0.3s;
        @include sp {
          padding: 15px 10px;
        }
        &:hover {
          background-color: rgba( $blue, 0.1);
          opacity: 1;
        }
      }
    }

    &--wrap {
      display: flex;
      align-items: center;
      @include sp {
        display: block;
      }
    }

    &--left {
      width: 230px;
      display: flex;
      align-items: center;
      @include sp {
        width: 100%;
        margin-bottom: 10px;
      }
    }

    &--right {
      width: calc( 100% - 230px );
      @include sp {
        width: 100%;
      }
    }

    &--date {
      font-size: 16px;
      font-weight: bold;
      @include sp {
        font-size: 14px;
      }
    }

    &--category {
      color: $white;
      font-size: 14px;
      line-height: 1;
      padding: 5px 15px 6px;
      margin-left: 35px;
      background-color: $blue;
      display: block;
      @include sp {
        margin-left: 20px;
      }
    }

    &--title {
      font-size: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &_btn {
    max-width: 365px;
    width: 100%;
    margin: 0 auto;
  }

  &_pagenation {
    margin-top: 80px;
    @include sp {
      margin-top: 40px;
    }
  }

  &_detail {
    &--date {
      font-size: 16px;
      margin-bottom: 25px;
      @include sp {
        font-size: 14px;
        margin-bottom: 20px;
      }
    }

    &--inner {
      padding-top: 60px;
      border-top: 1px solid rgba( $blue, 0.4);
      @include sp {
        padding-top: 30px;
      }
    }

    &--wrap {
      max-width: 1040px;
      width: 100%;
      margin: 0 auto;
      line-height: 2;

      h1 {
        color: $blue;
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 25px;
        @include sp {
          font-size: 22px;
          margin-bottom: 15px;
        }
      }

      h2 {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 15px;
      }
      
      p {
        font-size: 16px;
        @include sp {
          font-size: 14px;
        }
      }

      a {
        color: $blue;
        text-decoration: underline;
      }
    }
  }
}