*, *:before, *:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

body {
  color: $black;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
}

.main {
  padding-top: 89px;
  @include tab02 {
    padding-top: 152px; 
  }
  @include sp {
    padding-top: 69px;
  }
}

h1,h2,h3,h4,h5,h6 {
  font-weight: normal;
}

a {
  color: $black;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 16px;
  font-weight: 400;
  transition: 0.3s;
  display: block;

  &:hover {
    opacity: 0.8;
  }
}

.container {
  max-width: 1160px;
  width: 88.8%;
  margin: 0 auto;
}

img {
  max-width: 100%;
  vertical-align: bottom;
}

.pc {
  @include pc {
    display: block;
  }

  @include sp {
    display: none;
  }
}

.tab {
  @include pc {
    display: none;
  }

  @include tab {
    display: block;
  }

  @include sp {
    display: block;
  }
}

.sp {
  @include pc {
    display: none;
  }

  @include sp {
    display: block;
  }
}

.medium {
  font-weight: $medium;
}

.bold {
  font-weight: $bold;
}

.center {
  text-align: center;
}

.textRight {
  text-align: right;
}

button {
  background: none;
	border: none;
	outline: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.subpage {
  margin-bottom: 100px;
  @include sp {
    margin-bottom: 60px;
  }
}

.scrollHeader {
  margin-top: -130px;
  padding-top: 130px;

  @include sp {
    margin-top: -100px;
    padding-top: 100px;
  }
}

.indent {
  text-indent: -1em;
  padding-left: 1em;
  display: inline-block;
}

input{
  -webkit-appearance: none;
}

input[type="text"],
input[type="email"],
input[type="tel"] {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 16px;
  line-height: 1;
  width: 100%;
  height: 40px;
  background-color: $lightblue;
  padding: 10px 25px;
  border: none;
  border-radius: 0;
  outline: none;
  @include sp {
    font-size: 14px;
    padding: 10px 15px;
  }

  &::placeholder {
    line-height: 1;
  }

  &.w49 {
    width: 49%;
  }
}

input[type="checkbox"] {
  display: none;

  &:checked + label {
    background: #ff0000;
  }
}

.checkbox {
  label {
    cursor: pointer;
    span {
      font-size: 14px;
      font-weight: 500;
      padding-left: 35px;
      margin-right: 40px;
      display: inline-block;
      position: relative;
      @include tab {
        margin-right: 25px;
      }
  
      &:before {
        content: '';
        width: 20px;
        height: 20px;
        border: 1px solid #a7b9d5;
        position: absolute;
        left: 0;
        top: 3px;
        @include sp {
          top: 1px;
        }
      }
    }
  }
  

  a {
    color: $blue;
    text-decoration: underline;
    display: inline;
    line-height: 1;
  }

  input {
    display: none;

    &:checked + span {
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 4px;
        left: 6px;
        width: 8px;
        height: 14px;
        transform: rotate(45deg);
        border-bottom: 2px solid $blue;
        border-right: 2px solid $blue;
        @include sp {
          top: 2px;
        }
      }
    }
  }
}

textarea {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 2;
  width: 100%;
  height: 265px;
  border: 1px solid #a7b9d5;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  resize: none;
  padding: 10px 25px;
  outline: none;
  background: transparent;
  -webkit-appearance: none;
  @include sp {
    font-size: 14px;
    padding: 10px 15px;
  }

  &::placeholder {
    color: #8e8e8e;
  }
}

.flex {
  display: flex;
}

.js-modal_target {
  cursor: pointer;
}

.roboto {
  font-family: 'Roboto', sans-serif;
}