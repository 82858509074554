@charset "UTF-8";

/* 
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com 
Twitter: @rich_clark
*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

li {
  list-style: none;
}

body {
  line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input,
select,
img {
  vertical-align: bottom;
}

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  color: #222;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
}

.main {
  padding-top: 89px;
}

@media screen and (min-width: 768px) and (max-width: 1050px) {
  .main {
    padding-top: 152px;
  }
}

@media screen and (max-width: 767px) {
  .main {
    padding-top: 69px;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

a {
  color: #222;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 16px;
  font-weight: 400;
  transition: 0.3s;
  display: block;
}

a:hover {
  opacity: 0.8;
}

.container {
  max-width: 1160px;
  width: 88.8%;
  margin: 0 auto;
}

img {
  max-width: 100%;
  vertical-align: bottom;
}

@media screen and (min-width: 768px) {
  .pc {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .pc {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .tab {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .tab {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .tab {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .tab {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .sp {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .sp {
    display: block;
  }
}

.medium {
  font-weight: 500;
}

.bold {
  font-weight: 700;
}

.center {
  text-align: center;
}

.textRight {
  text-align: right;
}

button {
  background: none;
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.subpage {
  margin-bottom: 100px;
}

@media screen and (max-width: 767px) {
  .subpage {
    margin-bottom: 60px;
  }
}

.scrollHeader {
  margin-top: -130px;
  padding-top: 130px;
}

@media screen and (max-width: 767px) {
  .scrollHeader {
    margin-top: -100px;
    padding-top: 100px;
  }
}

.indent {
  text-indent: -1em;
  padding-left: 1em;
  display: inline-block;
}

input {
  -webkit-appearance: none;
}

input[type="text"],
input[type="email"],
input[type="tel"] {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 16px;
  line-height: 1;
  width: 100%;
  height: 40px;
  background-color: #e8ecf3;
  padding: 10px 25px;
  border: none;
  border-radius: 0;
  outline: none;
}

@media screen and (max-width: 767px) {
  input[type="text"],
  input[type="email"],
  input[type="tel"] {
    font-size: 14px;
    padding: 10px 15px;
  }
}

input[type="text"]::placeholder,
input[type="email"]::placeholder,
input[type="tel"]::placeholder {
  line-height: 1;
}

input[type="text"].w49,
input[type="email"].w49,
input[type="tel"].w49 {
  width: 49%;
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"]:checked + label {
  background: #ff0000;
}

.checkbox label {
  cursor: pointer;
}

.checkbox label span {
  font-size: 14px;
  font-weight: 500;
  padding-left: 35px;
  margin-right: 40px;
  display: inline-block;
  position: relative;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .checkbox label span {
    margin-right: 25px;
  }
}

@media screen and (max-width: 767px) {
  .checkbox label span {
    margin-right: 25px;
  }
}

.checkbox label span:before {
  content: '';
  width: 20px;
  height: 20px;
  border: 1px solid #a7b9d5;
  position: absolute;
  left: 0;
  top: 3px;
}

@media screen and (max-width: 767px) {
  .checkbox label span:before {
    top: 1px;
  }
}

.checkbox a {
  color: #255096;
  text-decoration: underline;
  display: inline;
  line-height: 1;
}

.checkbox input {
  display: none;
}

.checkbox input:checked + span:after {
  content: '';
  display: block;
  position: absolute;
  top: 4px;
  left: 6px;
  width: 8px;
  height: 14px;
  transform: rotate(45deg);
  border-bottom: 2px solid #255096;
  border-right: 2px solid #255096;
}

@media screen and (max-width: 767px) {
  .checkbox input:checked + span:after {
    top: 2px;
  }
}

textarea {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 2;
  width: 100%;
  height: 265px;
  border: 1px solid #a7b9d5;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  resize: none;
  padding: 10px 25px;
  outline: none;
  background: transparent;
  -webkit-appearance: none;
}

@media screen and (max-width: 767px) {
  textarea {
    font-size: 14px;
    padding: 10px 15px;
  }
}

textarea::placeholder {
  color: #8e8e8e;
}

.flex {
  display: flex;
}

.js-modal_target {
  cursor: pointer;
}

.roboto {
  font-family: 'Roboto', sans-serif;
}

.l-header {
  width: 100%;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
}

.l-header_inner {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;
}

@media screen and (max-width: 1200px) and (min-width: 1051px) {
  .l-header_inner {
    padding: 0 20px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1050px) {
  .l-header_inner {
    padding: 0;
  }
}

@media screen and (max-width: 767px) {
  .l-header_inner {
    padding: 0;
  }
}

.l-header_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (min-width: 768px) and (max-width: 1050px) {
  .l-header_wrap {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .l-header_wrap {
    display: block;
  }
}

.l-header_logo {
  max-width: 140px;
  width: 10%;
}

.l-header_logo img {
  vertical-align: baseline;
}

@media screen and (min-width: 768px) and (max-width: 1050px) {
  .l-header_logo {
    width: 100%;
    margin: 20px 20px 0;
  }
}

@media screen and (max-width: 767px) {
  .l-header_logo {
    max-width: none;
    width: calc( 100% - 67px);
    height: 63px;
    margin: 0;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (max-width: 767px) {
  .l-header_logo h1 {
    max-width: 80px;
    width: 100%;
    display: block;
  }
}

.l-header_navi {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media screen and (max-width: 767px) {
  .l-header_navi {
    display: none;
  }
}

.l-header_submenu {
  width: 100%;
  padding-top: 9px;
  position: absolute;
  top: calc( 100% - 9px);
  left: 0;
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;
}

.l-header_submenu--inner {
  padding: 40px 0;
  background-color: rgba(37, 80, 150, 0.8);
}

.l-header_submenu--list {
  display: flex;
  justify-content: center;
}

.l-header_submenu--item {
  max-width: 180px;
}

.l-header_submenu--item:not(:last-child) {
  margin-right: 1.4%;
}

.l-header_submenu--pic {
  margin-bottom: 10px;
}

.l-header_submenu--pic img {
  height: auto;
}

.l-header_submenu--title {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  font-feature-settings: 'palt';
  text-align: center;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .l-header_submenu--title {
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  .l-header_submenu--title {
    font-size: 14px;
  }
}

.l-header_submenu--title span {
  padding-right: 20px;
  position: relative;
  display: inline-block;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .l-header_submenu--title span {
    padding-right: 15px;
  }
}

@media screen and (max-width: 767px) {
  .l-header_submenu--title span {
    padding-right: 15px;
  }
}

.l-header_submenu--title span:after {
  content: '';
  width: 8px;
  height: 8px;
  border: 0px;
  border-top: solid 2px #fff;
  border-right: solid 2px #fff;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -2px;
}

.l-header_menu {
  display: flex;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .l-header_menu {
    width: 100%;
    justify-content: space-between;
  }
}

@media screen and (max-width: 767px) {
  .l-header_menu {
    width: 100%;
    justify-content: space-between;
  }
}

.l-header_menu--btn {
  position: relative;
}

.l-header_menu--btn:before {
  content: '';
  width: 0;
  height: 4px;
  margin: 0 auto;
  background-color: #255096;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  transition: 0.3s;
}

.l-header_menu--btn:after {
  content: '';
  width: 1px;
  height: 12px;
  margin: auto 0;
  background-color: #c3c3c3;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.l-header_menu--btn a {
  font-size: 14px;
  font-weight: 500;
  height: 80px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  line-height: 1;
}

@media screen and (max-width: 1200px) {
  .l-header_menu--btn a {
    font-size: 13px;
    padding: 0 10px;
  }
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .l-header_menu--btn a {
    font-size: 13px;
  }
}

@media screen and (max-width: 767px) {
  .l-header_menu--btn a {
    font-size: 13px;
  }
}

.l-header_menu--btn a:hover {
  opacity: 1;
}

.l-header_menu--btn .arrow {
  padding-right: 20px;
  position: relative;
}

.l-header_menu--btn .arrow:after {
  content: '';
  width: 8px;
  height: 8px;
  border: 0px;
  border-top: solid 2px #222;
  border-right: solid 2px #222;
  -ms-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -5px;
}

.l-header_menu--btn.current:before {
  width: 100%;
}

.l-header_menu--item:first-child .l-header_menu--btn:after {
  display: none;
}

.l-header_menu--item.is-view .l-header_menu--btn:before {
  width: 100%;
}

.l-header_menu--item.is-view .l-header_submenu {
  opacity: 1;
  pointer-events: auto;
}

.l-header_menu--item a.new {
  position: relative;
}

.l-header_menu--item a.new:before {
  content: 'new';
  font-size: 12px;
  color: red;
  position: absolute;
  top: 17px;
  left: 5px;
  animation: blinking 1s infinite;
}

@keyframes blinking {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.l-header_company {
  max-width: 183px;
  width: 13%;
  margin-left: 30px;
}

@media screen and (max-width: 1200px) and (min-width: 1051px) {
  .l-header_company {
    margin-left: 15px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1050px) {
  .l-header_company {
    width: 100%;
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

@media screen and (max-width: 767px) {
  .l-header_company {
    max-width: 105px;
    width: 100%;
    margin: 0;
    position: static;
  }
}

.l-header_company img {
  vertical-align: baseline;
}

.l-header_border {
  width: 100%;
  height: 9px;
  background-color: #e4e4ec;
  position: relative;
  z-index: -1;
}

@media screen and (max-width: 767px) {
  .l-header_border {
    height: 6px;
  }
}

.l-header_border:before {
  content: '';
  width: calc( 50% - 700px);
  height: 100%;
  background-color: #525F93;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 767px) {
  .l-header_border:before {
    display: none;
  }
}

.l-header_border--bg {
  max-width: 1400px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: url("../img/common/header_bg01.png") no-repeat left;
}

@media screen and (max-width: 767px) {
  .l-header_border--bg {
    background-size: 35px auto;
  }
}

.l-header_spBtn {
  width: 67px;
  height: 63px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  transition: 0.3s;
  z-index: 102;
}

@media screen and (min-width: 768px) {
  .l-header_spBtn {
    display: none;
  }
}

.l-header_spBtn span {
  width: 30px;
  height: 2px;
  margin: auto;
  background-color: #255096;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  transition: 0.3s;
}

.l-header_spBtn span:first-child {
  transform: translateY(-10px);
}

.l-header_spBtn span:last-child {
  transform: translateY(10px);
}

.l-header_spBtn.is-active {
  background-color: #255096;
}

.l-header_spBtn.is-active span {
  background-color: #fff;
}

.l-header_spBtn.is-active span:first-child {
  transform: rotate(-45deg);
}

.l-header_spBtn.is-active span:nth-child(2) {
  opacity: 0;
}

.l-header_spBtn.is-active span:last-child {
  transform: rotate(45deg);
}

.l-header_spNavi {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 69px;
  left: 100%;
  transition: 0.3s;
}

.l-header_spNavi.is-view {
  left: 0;
}

.l-header_spNavi--colse {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  cursor: pointer;
}

.l-header_spNavi--scroll {
  max-height: calc( 100vh - 69px);
  overflow-y: scroll;
  position: relative;
  z-index: 11;
}

.l-header_spNavi--inner {
  padding: 40px 0;
  background-color: rgba(26, 64, 123, 0.9);
}

.l-header_spNavi--container {
  width: 78%;
  margin: 0 auto;
}

.l-header_spNavi--list {
  margin-bottom: 40px;
}

.l-header_spNavi--btn {
  border-bottom: 1px solid #fff;
}

.l-header_spNavi--btn a {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 30px 10px 15px;
  position: relative;
}

.l-header_spNavi--btn a:after {
  content: '';
  width: 8px;
  height: 8px;
  border: 0px;
  border-top: solid 2px #fff;
  border-right: solid 2px #fff;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -4px;
}

.l-header_spNavi--btn.js-dropdown a:before, .l-header_spNavi--btn.js-dropdown a:after {
  content: '';
  margin: auto;
  background-color: #fff;
  top: 0;
  bottom: 0;
  position: absolute;
  transition: 0.3s;
}

.l-header_spNavi--btn.js-dropdown a:before {
  width: 12px;
  height: 1px;
  right: 10px;
}

.l-header_spNavi--btn.js-dropdown a:after {
  width: 1px;
  height: 12px;
  right: 15px;
  transform: rotate(0);
  border: none;
}

.l-header_spNavi--btn.js-dropdown.is-open a:before {
  opacity: 0;
}

.l-header_spNavi--btn.js-dropdown.is-open a:after {
  transform: rotate(90deg);
}

.l-header_spNavi--wrap {
  display: flex;
  align-items: center;
}

.l-header_spNavi--main {
  width: 150px;
}

.l-header_spNavi--main.new:after {
  content: 'new';
  color: red;
  font-size: 12px;
  margin-left: 10px;
  animation: blinking 1s infinite;
}

@keyframes blinking {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.l-header_spNavi--sub {
  font-size: 10px;
  width: calc( 100% - 150px);
}

.l-header_spNavi--subList {
  padding-left: 15px;
  display: none;
}

.l-header_spNavi--subList a {
  font-size: 12px;
}

.l-header_spNavi .c-btn a {
  border: 1px solid #fff;
  background-image: url("../img/common/btn_bg01.png");
}

.l-header_spNavi .c-btn a:before {
  border-color: #255096;
}

.l-header_spNavi .c-btn a:after {
  background-color: #fff;
}

.l-header_spNavi .c-btn a:hover {
  color: #255096;
}

.l-footer_contact {
  padding: 5.7% 0;
  position: relative;
}

@media screen and (max-width: 767px) {
  .l-footer_contact {
    padding: 40px 0;
  }
}

.l-footer_contact.sub {
  padding-top: 0;
}

.l-footer_contact--box {
  position: relative;
  z-index: 3;
}

@media screen and (max-width: 767px) {
  .l-footer_contact .c-heading_cmn {
    margin-bottom: 15px;
  }
}

.l-footer_contact--text {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 2.85%;
}

@media screen and (max-width: 767px) {
  .l-footer_contact--text {
    font-size: 20px;
  }
}

.l-footer_contact--btn {
  max-width: 365px;
  width: 96%;
  margin: 0 auto;
}

.l-footer_inner {
  padding: 60px 0;
  border-top: 1px solid #255096;
}

.l-footer_wrap {
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .l-footer_wrap {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .l-footer_wrap {
    display: block;
  }
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .l-footer_left {
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (max-width: 767px) {
  .l-footer_left {
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (max-width: 767px) {
  .l-footer_left {
    margin-bottom: 30px;
    display: block;
    text-align: center;
  }
}

.l-footer_logo {
  width: 186px;
  height: 51px;
  margin-bottom: 50px;
  display: block;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .l-footer_logo {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) {
  .l-footer_logo {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) {
  .l-footer_logo {
    margin: 0 auto 30px;
  }
}

.l-footer_logo img {
  width: 100%;
}

.l-footer_company--logo {
  width: 183px;
  margin-bottom: 10px;
  display: block;
}

@media screen and (max-width: 767px) {
  .l-footer_company--logo {
    margin: 0 auto 10px;
  }
}

.l-footer_company--address {
  font-size: 14px;
}

.l-footer_right--wrap {
  display: flex;
}

@media screen and (max-width: 767px) {
  .l-footer_right--wrap {
    display: block;
  }
}

.l-footer_navi:not(:last-child) {
  margin-right: 40px;
}

@media screen and (max-width: 767px) {
  .l-footer_navi:not(:last-child) {
    margin: 0;
  }
}

.l-footer_navi a, .l-footer_navi h2 {
  color: #255096;
}

@media screen and (max-width: 767px) {
  .l-footer_navi a, .l-footer_navi h2 {
    padding: 10px 15px;
    border-bottom: 1px solid #255096;
    position: relative;
  }
}

@media screen and (max-width: 767px) {
  .l-footer_navi a:after {
    content: '';
    width: 6px;
    height: 6px;
    margin: auto;
    border: 0px;
    border-top: solid 2px #255096;
    border-right: solid 2px #255096;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
  }
}

@media screen and (max-width: 767px) {
  .l-footer_navi h2 {
    position: relative;
    cursor: pointer;
  }
  .l-footer_navi h2:before, .l-footer_navi h2:after {
    content: '';
    margin: auto;
    background-color: #255096;
    position: absolute;
    top: 0;
    bottom: 0;
    transition: 0.3s;
  }
  .l-footer_navi h2:before {
    width: 10px;
    height: 1px;
    right: 10px;
  }
  .l-footer_navi h2:after {
    width: 1px;
    height: 10px;
    right: 15px;
  }
  .l-footer_navi h2.is-open:before {
    opacity: 0;
  }
  .l-footer_navi h2.is-open:after {
    transform: rotate(90deg);
  }
}

.l-footer_navi--item:not(:last-child) {
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .l-footer_navi--item:not(:last-child) {
    margin-bottom: 0 !important;
  }
}

.l-footer_navi--item > a, .l-footer_navi--item h2 {
  font-size: 16px;
  font-weight: 700;
}

.l-footer_navi--item > a a, .l-footer_navi--item h2 a {
  font-size: 16px;
  font-weight: 700;
}

.l-footer_navi--item a[target="_blank"] {
  padding-right: 27px;
  position: relative;
}

.l-footer_navi--item a[target="_blank"]:before {
  content: '';
  width: 17px;
  height: 13px;
  margin: auto 0;
  background: url("../img/common/ico_link01.svg") no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

@media screen and (max-width: 767px) {
  .l-footer_navi--item a[target="_blank"]:before {
    right: 10px;
  }
}

@media screen and (max-width: 767px) {
  .l-footer_navi--item a[target="_blank"]:after {
    display: none;
  }
}

.l-footer_subNavi {
  padding-left: 18px;
}

@media screen and (max-width: 767px) {
  .l-footer_subNavi {
    padding-left: 15px;
    display: none;
  }
}

.l-footer_subNavi li {
  margin-top: 15px;
}

@media screen and (max-width: 767px) {
  .l-footer_subNavi li {
    margin: 0;
  }
}

.l-footer_subNavi a {
  font-size: 14px;
}

.l-footer_bottom {
  padding: 15px 0;
  background-color: #255096;
}

.l-footer_bottom--inner {
  max-width: 1160px;
  width: 96%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .l-footer_bottom--inner {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .l-footer_bottom--inner {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .l-footer_bottom--inner {
    display: block;
  }
}

.l-footer_bottom--list {
  display: flex;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .l-footer_bottom--list {
    margin-bottom: 10px;
    justify-content: center;
  }
}

@media screen and (max-width: 767px) {
  .l-footer_bottom--list {
    margin-bottom: 10px;
    justify-content: center;
  }
}

@media screen and (max-width: 767px) {
  .l-footer_bottom--list {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 10px;
  }
}

.l-footer_bottom--list li {
  margin-right: 25px;
}

@media screen and (max-width: 767px) {
  .l-footer_bottom--list li {
    width: 48%;
    margin-right: 0;
  }
}

.l-footer_bottom--list a {
  color: #fff;
  font-size: 14px;
}

.l-footer_bottom--list a[target='_blank'] {
  padding-right: 27px;
  position: relative;
}

.l-footer_bottom--list a[target='_blank']:after {
  content: "";
  width: 17px;
  height: 13px;
  margin: auto 0;
  background: url("../img/common/ico_link01_on.svg") no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.l-footer_copyright {
  color: #fff;
  font-size: 12px;
  text-align: center;
}

.c-btn a, .c-btn button {
  cursor: pointer;
  display: block;
}

.c-btn_cmn {
  width: 100%;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  border: 1px solid #fff;
  background: url("../img/common/btn_bg01.png") no-repeat right top;
  padding: 14px 28px 14px 14px;
  position: relative;
  transition: 0.3;
  cursor: pointer;
  display: block;
}

.c-btn_cmn:before {
  content: '';
  width: 6px;
  height: 6px;
  margin: auto 0;
  border: 0px;
  border-top: solid 2px #255096;
  border-right: solid 2px #255096;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 5.5%;
  z-index: 2;
}

.c-btn_cmn:after {
  content: '';
  width: 0;
  height: 100%;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  transition: 0.3s;
  z-index: 1;
}

.c-btn_cmn span {
  position: relative;
  z-index: 2;
  transition: 0.1s;
}

.c-btn_cmn:hover {
  color: #255096;
  background-position: -68px;
  opacity: 1;
}

.c-btn_cmn:hover:before {
  border-color: #255096;
}

.c-btn_cmn:hover:after {
  width: 100%;
}

.c-btn_cmn.blue {
  color: #fff;
  background-color: #255096;
  background-image: url("../img/common/btn_bg01_blue.png");
  border: none;
}

.c-btn_cmn.blue:before {
  border-color: #fff;
}

.c-btn_cmn.blue:after {
  background-color: #1a407b;
}

.c-btn_map {
  max-width: 172px;
  width: 100%;
  color: #255096;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  padding: 4px 0 6px;
  background-color: #fff;
  border: 1px solid #255096;
  transition: 0.3s;
}

.c-btn_map:hover {
  color: #fff;
  background-color: #255096;
  opacity: 1;
}

.c-btn_map.blue {
  color: #fff;
  background-color: #255096;
}

.c-btn_map.blue:hover {
  color: #255096;
  background-color: #fff;
}

.c-btn_modal {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  max-width: 172px;
  width: 100%;
  height: 31px;
  background-color: #255096;
  border: 1px solid #255096;
  position: relative;
  cursor: pointer;
  transition: 0.3s;
}

.c-btn_modal:before, .c-btn_modal:after {
  content: '';
  margin: auto;
  background-color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  transition: 0.3s;
}

.c-btn_modal:before {
  width: 10px;
  height: 1px;
  right: 15px;
}

.c-btn_modal:after {
  width: 1px;
  height: 10px;
  right: 20px;
}

.c-btn_modal:hover {
  color: #255096;
  background-color: #fff;
}

.c-btn_modal:hover:before, .c-btn_modal:hover:after {
  background-color: #255096;
}

.c-btn_pagenation {
  width: 54px;
  height: 52px;
  background-color: #255096;
  border: 1px solid #255096;
  position: relative;
  transition: 0.3s;
}

@media screen and (max-width: 767px) {
  .c-btn_pagenation {
    width: 35px;
    height: 40px;
  }
}

@media screen and (max-width: 360px) {
  .c-btn_pagenation {
    width: 30px;
    height: 35px;
  }
}

.c-btn_pagenation span {
  width: 8px;
  height: 8px;
  border: 0px;
  margin: -3px auto 0;
  border-top: solid 2px #fff;
  border-right: solid 2px #fff;
  -ms-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transition: 0.3s;
}

@media screen and (max-width: 767px) {
  .c-btn_pagenation span {
    width: 6px;
    height: 6px;
  }
}

.c-btn_pagenation.next span {
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.c-btn_pagenation:hover {
  background-color: #fff;
  opacity: 1;
}

.c-btn_pagenation:hover span {
  border-color: #255096;
}

.c-btn_back {
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  height: 52px;
  background-color: #e8ecf3;
  cursor: pointer;
  display: block;
}

.c-heading_container {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}

.c-heading_cmn {
  color: #222;
  font-size: 22px;
  font-weight: 700;
  font-feature-settings: 'palt';
  text-align: center;
  margin-bottom: 25px;
  letter-spacing: 1px;
}

@media screen and (max-width: 767px) {
  .c-heading_cmn {
    font-size: 20px;
  }
}

.c-heading_cmn--sub {
  color: #255096;
  font-size: 10px;
  font-weight: 500;
  margin-top: 5px;
  display: block;
}

.c-heading_cmn.white {
  color: #fff;
}

.c-heading_cmn.white .c-heading_cmn--sub {
  color: #fff;
}

.c-heading_blue {
  color: #255096;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 25px;
}

@media screen and (max-width: 767px) {
  .c-heading_blue {
    font-size: 18px;
  }
}

.c-heading_point {
  color: #255096;
  font-size: 22px;
  padding-bottom: 15px;
  margin-bottom: 25px;
  border-bottom: 1px solid #255096;
}

@media screen and (max-width: 767px) {
  .c-heading_point {
    font-size: 18px;
  }
}

.c-heading_read {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .c-heading_read {
    font-size: 18px;
  }
}

.c-mv {
  margin-bottom: 80px;
  position: relative;
}

@media screen and (max-width: 767px) {
  .c-mv {
    margin-bottom: 60px;
  }
}

.c-mv_inner {
  width: 100%;
  min-height: 300px;
  padding: 60px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .c-mv_inner {
    min-height: 225px;
    padding: 40px 20px;
  }
}

.c-mv_heading {
  color: #fff;
}

.c-mv_heading h2 {
  font-size: 30px;
}

@media screen and (max-width: 767px) {
  .c-mv_heading h2 {
    font-size: 22px;
  }
}

.c-mv_sub {
  font-size: 10px;
  margin-top: 15px;
}

@media screen and (max-width: 767px) {
  .c-mv_sub {
    margin-bottom: 10px;
  }
}

.c-mv_text {
  font-size: 16px;
  margin-top: 30px;
}

@media screen and (max-width: 767px) {
  .c-mv_text {
    font-size: 14px;
    margin-top: 20px;
  }
}

.c-mv_breadcrumb {
  display: flex;
  position: absolute;
  top: 25px;
  left: 30px;
}

@media screen and (max-width: 767px) {
  .c-mv_breadcrumb {
    display: none;
  }
}

.c-mv_breadcrumb a {
  color: #fff;
  font-size: 12px;
}

.c-mv_breadcrumb li:not(:last-child) {
  padding-right: 15px;
  margin-right: 15px;
  position: relative;
}

.c-mv_breadcrumb li:not(:last-child):after {
  content: '';
  width: 6px;
  height: 6px;
  border: 0px;
  border-top: solid 2px #fff;
  border-right: solid 2px #fff;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -2px;
}

.c-mv_breadcrumb .current a {
  text-decoration: underline;
}

.c-mv.network .c-mv_inner {
  background: url("../img/network/mv_bg01.jpg") no-repeat center;
  background-size: cover;
}

.c-mv.company .c-mv_inner {
  background: url("../img/company/mv_bg01.jpg") no-repeat center;
  background-size: cover;
}

.c-mv.news .c-mv_inner, .c-mv.privacy .c-mv_inner, .c-mv.contact .c-mv_inner {
  height: 200px;
  background: url("../img/news/mv_bg01.jpg") no-repeat center;
  background-size: cover;
}

.c-mv.news .c-mv_inner {
  min-height: 200px;
  height: auto;
  padding: 60px 0;
}

@media screen and (max-width: 767px) {
  .c-mv.news .c-mv_inner {
    padding: 40px 20px;
  }
}

@media screen and (max-width: 767px) {
  .c-mv.news h2 {
    font-size: 18px;
  }
}

.c-mv.strengths .c-mv_inner {
  background: url("../img/strengths/mv_bg01.jpg") no-repeat center;
  background-size: cover;
}

.c-mv.works .c-mv_inner {
  background: url("../img/works/mv_bg01.jpg") no-repeat center;
  background-size: cover;
}

.c-mv.service .c-mv_inner {
  background: url("../img/service/mv_bg01.jpg") no-repeat center;
  background-size: cover;
}

.c-mv.eclogistics .c-mv_inner {
  background: url("../img/service/eclogistics_mv01.jpg") no-repeat center;
  background-size: cover;
}

.c-mv.electronics .c-mv_inner {
  background: url("../img/service/eclogistics_mv01.jpg") no-repeat center;
  background-size: cover;
}

.c-mv.material .c-mv_inner {
  background: url("../img/service/material/mv_bg01.jpg") no-repeat center;
  background-size: cover;
}

.c-mv.funiture .c-mv_inner {
  background: url("../img/service/funiture/mv_bg01.jpg") no-repeat center;
  background-size: cover;
}

.c-mv.partnership .c-mv_inner {
  background: url("../img/service/partnership/mv_bg01.jpg") no-repeat center;
  background-size: cover;
}

.c-mv.logisticsequipment .c-mv_inner {
  background: url("../img/service/eclogistics_mv01.jpg") no-repeat center;
  background-size: cover;
}

.c-mv.system .c-mv_inner {
  background: url("../img/system/mv_bg01.jpg") no-repeat center;
  background-size: cover;
}

.c-mv.wms .c-mv_inner {
  background: url("../img/system/wms_mv01.jpg") no-repeat center;
  background-size: cover;
}

.c-mv.tms .c-mv_inner {
  background: url("../img/system/tms_mv01.jpg") no-repeat center;
  background-size: cover;
}

.c-box_gray {
  background-color: #eff1f3;
}

.c-box_white {
  background-color: #fff;
}

.c-box_intro {
  margin-bottom: 90px;
}

@media screen and (max-width: 767px) {
  .c-box_intro {
    margin-bottom: 60px;
  }
}

.c-box_intro--read {
  font-size: 22px;
  line-height: 1.5;
  margin-bottom: 25px;
}

@media screen and (max-width: 767px) {
  .c-box_intro--read {
    font-size: 18px;
    margin-bottom: 20px;
  }
}

.c-box_intro--text {
  font-size: 18px;
  line-height: 1.8;
}

@media screen and (max-width: 767px) {
  .c-box_intro--text {
    font-size: 14px;
    text-align: left;
  }
}

.c-box_point {
  padding: 60px 0;
  background-color: #eff1f3;
}

@media screen and (max-width: 767px) {
  .c-box_point {
    padding: 30px 0;
  }
}

.c-box_point--wrap {
  max-width: 1040px;
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-box_point--wrap {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .c-box_point--wrap {
    display: block;
  }
}

.c-box_point--textBox {
  max-width: 400px;
  width: 38.5%;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-box_point--textBox {
    max-width: none;
    width: 100%;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) {
  .c-box_point--textBox {
    max-width: none;
    width: 100%;
    margin-bottom: 30px;
  }
}

.c-box_point--read {
  color: #255096;
  font-size: 22px;
  margin-bottom: 25px;
}

@media screen and (max-width: 767px) {
  .c-box_point--read {
    font-size: 18px;
    margin-bottom: 15px;
  }
}

.c-box_point--text {
  font-size: 16px;
  line-height: 1.6;
}

@media screen and (max-width: 767px) {
  .c-box_point--text {
    font-size: 14px;
  }
}

.c-box_point--pic {
  max-width: 590px;
  width: 56.5%;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-box_point--pic {
    max-width: none;
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .c-box_point--pic {
    max-width: none;
    width: 100%;
  }
}

.c-box_point--pic img {
  width: 100%;
}

.c-box_point--btn {
  max-width: 366px;
  margin-top: 25px;
}

.c-box_point:nth-child(even) {
  background-color: #fff;
}

.c-box_point:nth-child(even) .c-box_point--wrap {
  flex-direction: row-reverse;
}

.c-box_point.white {
  background-color: #fff;
}

.c-box_point.white .c-box_point--wrap {
  flex-direction: row-reverse;
}

.c-box_point .half .c-box_point--textBox {
  max-width: 493px;
  width: 47.4%;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-box_point .half .c-box_point--textBox {
    max-width: none;
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .c-box_point .half .c-box_point--textBox {
    max-width: none;
    width: 100%;
  }
}

.c-box_point .half .c-box_point--pic {
  max-width: 496px;
  width: 47.6%;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-box_point .half .c-box_point--pic {
    max-width: none;
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .c-box_point .half .c-box_point--pic {
    max-width: none;
    width: 100%;
  }
}

.c-box_point.system .c-box_point--wrap {
  align-items: center;
}

.c-modal_cont {
  max-width: 1160px;
  width: 83%;
  height: 90vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  overflow-y: scroll;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s;
}

.c-modal_cont.is-view {
  opacity: 1;
  pointer-events: auto;
}

.c-modal_cont--inner {
  padding: 60px 6.5%;
  background-color: #fff;
  position: relative;
}

@media screen and (max-width: 767px) {
  .c-modal_cont--inner {
    padding: 50px 4% 30px;
  }
}

.c-modal_cont--inner:before {
  content: '';
  width: 52.3%;
  height: 100%;
  background: url("../img/common/modal_bg01.png") no-repeat bottom right;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .c-modal_cont--inner:before {
    width: 75%;
    background-size: 100% 100%;
  }
}

.c-modal_close {
  width: 4.3%;
  position: absolute;
  top: 3.4%;
  right: 40px;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .c-modal_close {
    width: 20px;
    top: 10px;
    right: 15px;
  }
}

.c-modal_scroll {
  position: relative;
  z-index: 2;
}

.c-modal_top {
  display: flex;
  justify-content: space-between;
}

.c-modal_top--img {
  max-width: 486px;
  width: 47.6%;
}

.c-modal_overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(37, 80, 150, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
}

.c-modal_overlay.is-view {
  opacity: 1;
  pointer-events: auto;
}

.c-localLink {
  margin-bottom: 80px;
}

@media screen and (max-width: 767px) {
  .c-localLink {
    margin-bottom: 40px;
  }
}

.c-localLink_list {
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-localLink_list {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 767px) {
  .c-localLink_list {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 767px) {
  .c-localLink_list {
    flex-wrap: wrap;
  }
}

.c-localLink_item {
  width: 15.5%;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-localLink_item {
    width: 32%;
  }
}

@media screen and (max-width: 767px) {
  .c-localLink_item {
    width: 32%;
  }
}

@media screen and (max-width: 767px) {
  .c-localLink_item {
    width: 48%;
  }
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-localLink_item:nth-of-type(-n+3) {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 767px) {
  .c-localLink_item:nth-of-type(-n+3) {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 767px) {
  .c-localLink_item:not(:nth-last-child(-n+2)) {
    margin-bottom: 15px;
  }
}

.c-localLink_item a {
  color: #255096;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  height: 100%;
  padding: 10px 0 18px;
  border: 1px solid #255096;
  position: relative;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-localLink_item a:after {
  content: '';
  width: 7px;
  height: 7px;
  margin: auto;
  border: 0px;
  border-top: solid 1px #255096;
  border-right: solid 1px #255096;
  -ms-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  position: absolute;
  bottom: 10px;
  right: 0;
  left: 0;
  transition: 0.3s;
}

.c-localLink_item a span {
  text-align: center;
}

.c-localLink_item a[target="_blank"]:after {
  display: none;
}

.c-localLink_item a[target="_blank"]:before {
  content: '';
  width: 15px;
  height: 12px;
  margin: 0 auto;
  background: url("../img/common/ico_link01.svg") no-repeat;
  background-size: contain;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 5px;
  transition: 0.3s;
}

.c-localLink_item a:hover {
  color: #fff;
  background-color: #255096;
  opacity: 1;
}

.c-localLink_item a:hover:after {
  border-color: #fff;
}

.c-localLink_item a:hover:before {
  background-image: url("../img/common/ico_link01_on.svg");
}

.c-table {
  margin-bottom: 100px;
}

@media screen and (max-width: 767px) {
  .c-table {
    margin-bottom: 60px;
  }
}

.c-table_cmn tr {
  border-bottom: 1px solid rgba(37, 80, 150, 0.4);
}

.c-table_cmn tr:first-child {
  border-top: 1px solid rgba(37, 80, 150, 0.4);
}

.c-table_cmn th, .c-table_cmn td {
  font-size: 18px;
  padding: 23px 0;
  line-height: 1.7;
}

@media screen and (max-width: 767px) {
  .c-table_cmn th, .c-table_cmn td {
    font-size: 14px;
  }
}

.c-table_cmn th {
  color: #255096;
  font-weight: bold;
  width: 200px;
  text-align: left;
  padding: 23px 60px;
}

@media screen and (max-width: 767px) {
  .c-table_cmn th {
    width: 120px;
    padding: 15px 30px;
  }
}

.c-table_cmn td {
  width: calc( 100% - 200px);
}

@media screen and (max-width: 767px) {
  .c-table_cmn td {
    width: calc( 100% - 120px);
  }
}

.c-table_form {
  width: 100%;
  margin-bottom: 40px;
}

.c-table_form tr {
  border-bottom: 1px solid rgba(37, 80, 150, 0.4);
}

@media screen and (max-width: 767px) {
  .c-table_form tr {
    padding: 20px 10px;
    display: block;
  }
}

.c-table_form tr:first-child {
  border-top: 1px solid rgba(37, 80, 150, 0.4);
}

.c-table_form tr:last-child {
  display: none;
}

.c-table_form th {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  width: 250px;
  padding: 25px 0 25px 40px;
  vertical-align: middle;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-table_form th {
    width: 220px;
    padding-left: 20px;
  }
}

@media screen and (max-width: 767px) {
  .c-table_form th {
    width: 220px;
    padding-left: 20px;
  }
}

@media screen and (max-width: 767px) {
  .c-table_form th {
    width: 100%;
    display: block;
    padding: 0;
    margin-bottom: 15px;
  }
}

.c-table_form th .flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .c-table_form th .flex {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 767px) {
  .c-table_form th span {
    margin-left: 15px;
  }
}

.c-table_form th.verticalTop {
  padding-top: 30px;
  vertical-align: top;
}

@media screen and (max-width: 767px) {
  .c-table_form th.verticalTop {
    padding: 0;
  }
}

.c-table_form td {
  width: calc( 100% - 250px);
  padding: 25px 40px;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-table_form td {
    width: calc( 100% - 220px);
    padding: 25px 20px;
  }
}

@media screen and (max-width: 767px) {
  .c-table_form td {
    width: calc( 100% - 220px);
    padding: 25px 20px;
  }
}

@media screen and (max-width: 767px) {
  .c-table_form td {
    width: 100%;
    padding: 0;
    display: block;
  }
}

.c-table_form td .flex {
  display: block;
}

.c-table_form td .flex label {
  display: block;
}

.c-table_form td .flex label:not(:last-child) {
  margin-bottom: 10px;
}

.c-table_form--wrap {
  display: flex;
  justify-content: space-between;
}

.c-table_form--disc {
  font-size: 14px;
  margin-top: 10px;
}

.c-table_form--inputWrap {
  max-width: 550px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  .c-table_form--inputWrap {
    flex-direction: column;
  }
}

.c-table_form--inputWrap .horizontal-item {
  margin: 7.5px 0;
}

@media screen and (max-width: 767px) {
  .c-table_form--inputWrap .horizontal-item {
    margin: 5px 0;
  }
}

.c-table_form--inputWrap .horizontal-item + .horizontal-item {
  margin-left: 0 !important;
}

@media screen and (max-width: 767px) {
  .c-table_form--inputWrap .horizontal-item:not(:last-child) {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767px) {
  .c-table_form.confirm {
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  .c-table_form.confirm tr {
    padding: 0;
    border: none;
  }
}

.c-table_form.confirm tr:last-child {
  display: table-row;
}

.c-table_form.confirm th {
  width: 210px;
  background-color: #e8ecf3;
}

@media screen and (max-width: 767px) {
  .c-table_form.confirm th {
    font-size: 14px;
    width: 100%;
    padding: 10px 15px;
    margin: 0 auto;
  }
}

.c-table_form.confirm td {
  width: calc( 100% - 210px);
}

@media screen and (max-width: 767px) {
  .c-table_form.confirm td {
    width: 100%;
    min-height: 42px;
    padding: 10px 15px;
  }
}

.c-table_form.confirm .c-table_form--wrap {
  justify-content: flex-start;
}

.c-table_form.confirm .c-table_form--wrap .u-w_49 {
  width: auto;
}

.c-table_form.confirm .c-table_form--wrap .u-w_49:first-child {
  margin-right: 25px;
}

.c-table_form.confirm .c-table_form--disc {
  display: none;
}

.c-table_form.confirm .c-table_form--inputWrap {
  max-width: none;
}

.c-pagenation_wrap {
  display: flex;
  justify-content: center;
}

.c-pagenation a, .c-pagenation span {
  color: #255096;
  font-size: 14px;
  line-height: 1px;
  width: 54px;
  height: 52px;
  margin: 0 5px;
  border: 1px solid #255096;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .c-pagenation a, .c-pagenation span {
    font-size: 13px;
    width: 35px;
    height: 40px;
  }
}

@media screen and (max-width: 360px) {
  .c-pagenation a, .c-pagenation span {
    width: 30px;
    height: 35px;
  }
}

.c-pagenation a:hover, .c-pagenation span:hover {
  color: #fff;
  background-color: #255096;
  opacity: 1;
}

.c-pagenation a.current, .c-pagenation span.current {
  color: #fff;
  font-weight: bold;
  background-color: #255096;
}

.c-pagenation a.prev, .c-pagenation a.next, .c-pagenation span.prev, .c-pagenation span.next {
  font-size: 0;
  background-color: #255096;
  position: relative;
}

.c-pagenation a.prev:before, .c-pagenation a.next:before, .c-pagenation span.prev:before, .c-pagenation span.next:before {
  content: '';
  width: 8px;
  height: 8px;
  border: 0px;
  margin: -3px auto 0;
  border-top: solid 2px #fff;
  border-right: solid 2px #fff;
  -ms-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transition: 0.3s;
}

@media screen and (max-width: 767px) {
  .c-pagenation a.prev:before, .c-pagenation a.next:before, .c-pagenation span.prev:before, .c-pagenation span.next:before {
    width: 6px;
    height: 6px;
  }
}

.c-pagenation a.prev:hover, .c-pagenation a.next:hover, .c-pagenation span.prev:hover, .c-pagenation span.next:hover {
  background-color: #fff;
}

.c-pagenation a.prev:hover:before, .c-pagenation a.next:hover:before, .c-pagenation span.prev:hover:before, .c-pagenation span.next:hover:before {
  border-color: #255096;
}

.c-pagenation a.prev, .c-pagenation span.prev {
  margin-right: 25px;
}

@media screen and (max-width: 767px) {
  .c-pagenation a.prev, .c-pagenation span.prev {
    margin-right: 10px;
  }
}

.c-pagenation a.next, .c-pagenation span.next {
  margin-left: 25px;
}

@media screen and (max-width: 767px) {
  .c-pagenation a.next, .c-pagenation span.next {
    margin-left: 10px;
  }
}

.c-pagenation a.next:before, .c-pagenation span.next:before {
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.c-list_column3 {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .c-list_column3 {
    display: block;
  }
}

.c-list_column3--pic {
  margin-bottom: 20px;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .c-list_column3--pic {
    margin-bottom: 15px;
  }
}

.c-list_column3--pic img {
  transition: 0.3s;
}

.c-list_column3--heading {
  color: #255096;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .c-list_column3--heading {
    font-size: 16px;
    margin-bottom: 5px;
  }
}

.c-list_column3--text {
  font-size: 16px;
}

@media screen and (max-width: 767px) {
  .c-list_column3--text {
    font-size: 14px;
  }
}

.c-list_column3--item {
  width: 31.7%;
}

@media screen and (max-width: 767px) {
  .c-list_column3--item {
    width: 100%;
  }
  .c-list_column3--item:not(:last-child) {
    margin-bottom: 30px;
  }
}

.c-list_worry--item {
  font-size: 16px;
  padding-left: 50px;
  position: relative;
}

@media screen and (max-width: 767px) {
  .c-list_worry--item {
    font-size: 14px;
    padding-left: 37px;
  }
}

.c-list_worry--item:before {
  content: '';
  width: 33px;
  height: 33px;
  background: url("../img/service/ico_worry.png") no-repeat;
  background-size: contain;
  position: absolute;
  top: -4px;
  left: 0;
}

@media screen and (max-width: 767px) {
  .c-list_worry--item:before {
    width: 25px;
    height: 25px;
    top: -3px;
  }
}

.c-list_worry--item:not(:last-child) {
  margin-bottom: 35px;
}

@media screen and (max-width: 767px) {
  .c-list_worry--item:not(:last-child) {
    margin-bottom: 20px;
  }
}

.c-list_faq--item {
  border-bottom: 1px solid rgba(37, 80, 150, 0.4);
}

.c-list_faq--item:first-child {
  border-top: 1px solid rgba(37, 80, 150, 0.4);
}

.c-list_faq--question {
  padding: 30px 75px 30px 15px;
  position: relative;
  cursor: pointer;
  position: relative;
}

@media screen and (max-width: 767px) {
  .c-list_faq--question {
    padding: 20px 45px 20px 3%;
  }
}

.c-list_faq--question:before, .c-list_faq--question:after {
  content: '';
  position: absolute;
  margin: auto 0;
  background-color: #255096;
  top: 0;
  bottom: 0;
  transition: 0.4s;
}

.c-list_faq--question:before {
  width: 26px;
  height: 1px;
  right: 33px;
}

@media screen and (max-width: 767px) {
  .c-list_faq--question:before {
    width: 20px;
    right: 14px;
  }
}

.c-list_faq--question:after {
  height: 26px;
  width: 1px;
  right: 46px;
}

@media screen and (max-width: 767px) {
  .c-list_faq--question:after {
    height: 20px;
    right: 23px;
  }
}

.c-list_faq--question.is-open:before {
  opacity: 0;
}

.c-list_faq--question.is-open:after {
  transform: rotate(90deg);
}

.c-list_faq--answer {
  padding: 30px 15px;
  margin: 0 55px 35px;
  display: none;
}

@media screen and (max-width: 767px) {
  .c-list_faq--answer {
    padding: 20px 3%;
    margin: 0 45px 25px;
  }
}

.c-list_faq--text {
  font-size: 16px;
  padding-left: 55px;
  position: relative;
}

@media screen and (max-width: 767px) {
  .c-list_faq--text {
    font-size: 14px;
    padding-left: 35px;
  }
}

.c-list_faq--text span {
  color: #255096;
  font-size: 30px;
  font-weight: bold;
  line-height: 1;
  position: absolute;
  top: -2px;
  left: 0;
}

@media screen and (max-width: 767px) {
  .c-list_faq--text span {
    font-size: 22px;
    top: 0;
  }
}

.c-column_two {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .c-column_two {
    display: block;
  }
}

.c-column_two--box {
  width: 47.4%;
}

@media screen and (max-width: 767px) {
  .c-column_two--box {
    width: 100%;
  }
}

.p-mv {
  position: relative;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .p-mv {
    overflow: visible;
    margin-bottom: 60px;
  }
}

.p-mv .slick-dotted.slick-slider {
  margin-bottom: 0 !important;
}

.p-mv_slide--bg {
  width: 100%;
  height: auto;
  padding-bottom: 48.21%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
  .p-mv_slide--bg {
    height: 674px;
    padding: 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-mv_slide--bg {
    height: 580px;
  }
}

@media screen and (max-width: 767px) {
  .p-mv_slide--bg {
    height: 580px;
  }
}

@media screen and (max-width: 767px) {
  .p-mv_slide--bg {
    height: auto;
    padding-bottom: 151.7%;
  }
}

.p-mv_slide--item.item02 .p-mv_slide--bg {
  background-position: 75% center;
}

.p-mv_contWrap {
  width: 100%;
  display: flex;
  align-items: flex-end;
  position: absolute;
  left: 0;
  bottom: 0;
}

@media screen and (max-width: 767px) {
  .p-mv_contWrap {
    display: block;
    position: relative;
  }
}

.p-mv_left {
  min-width: 560px;
  width: 50%;
  position: relative;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-mv_left {
    min-width: 530px;
  }
}

@media screen and (max-width: 767px) {
  .p-mv_left {
    min-width: 530px;
  }
}

@media screen and (max-width: 767px) {
  .p-mv_left {
    min-width: auto;
    width: 100%;
    padding: 20px 0 30px;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.p-mv_left--box {
  width: calc(100% - 182px);
  height: 320px;
  background-color: rgba(37, 80, 150, 0.9);
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding-right: 4%;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-mv_left--box {
    width: calc( 100% - 170px);
    height: 280px;
  }
}

@media screen and (max-width: 767px) {
  .p-mv_left--box {
    width: calc( 100% - 170px);
    height: 280px;
  }
}

@media screen and (max-width: 767px) {
  .p-mv_left--box {
    width: 100%;
    height: auto;
    padding: 0;
    background-color: transparent;
  }
}

.p-mv_left--bgCont {
  width: 182px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-mv_left--bgCont {
    width: 170px;
  }
}

@media screen and (max-width: 767px) {
  .p-mv_left--bgCont {
    width: 170px;
  }
}

@media screen and (max-width: 767px) {
  .p-mv_left--bgCont {
    width: 100%;
  }
}

.p-mv_left--bg {
  width: 100%;
  height: 100%;
  position: relative;
}

.p-mv_left--bg:before {
  content: '';
  width: 115%;
  height: 100%;
  background-color: rgba(37, 80, 150, 0.9);
  position: absolute;
  top: 0;
  right: 89.5px;
  transform: skew(30deg, 0deg);
}

@media screen and (max-width: 767px) {
  .p-mv_left--bg:before {
    width: 110%;
    right: auto;
    left: -93px;
  }
}

@media screen and (max-width: 360px) {
  .p-mv_left--bg:before {
    width: 120%;
  }
}

.p-mv_left--wrap {
  max-width: 365px;
  width: 90%;
}

@media screen and (max-width: 767px) {
  .p-mv_left--wrap {
    max-width: none;
    width: 89%;
    margin: 0 auto;
    position: relative;
    z-index: 1;
  }
}

@media screen and (max-width: 767px) {
  .p-mv_left--wrap .c-btn {
    max-width: 295px;
    width: 100%;
    margin: 0 auto;
  }
}

.p-mv_right {
  width: 50%;
  height: 90px;
  background-color: rgba(26, 64, 123, 0.9);
  position: relative;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .p-mv_right {
    width: 68%;
    height: 60px;
    position: absolute;
    top: 100%;
    right: 0;
  }
}

.p-mv_right--bgCont {
  width: 50px;
  height: 100%;
  position: absolute;
  right: 100%;
  top: 0;
  overflow: hidden;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-mv_right--bgCont {
    width: 60px;
  }
}

@media screen and (max-width: 767px) {
  .p-mv_right--bgCont {
    width: 60px;
  }
}

@media screen and (max-width: 767px) {
  .p-mv_right--bgCont {
    width: 35px;
  }
}

.p-mv_right--bg {
  width: 100%;
  height: 100%;
  position: relative;
}

.p-mv_right--bg:before {
  content: '';
  width: 100%;
  height: 100%;
  background-color: rgba(26, 64, 123, 0.9);
  position: absolute;
  top: 0;
  left: 25.5px;
  transform: skew(30deg, 0deg);
}

@media screen and (max-width: 767px) {
  .p-mv_right--bg:before {
    width: 70px;
    left: 16.5px;
  }
}

.p-mv_read {
  color: #fff;
  font-size: 26px;
  font-weight: 500;
  line-height: 1.7;
  margin-bottom: 15px;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-mv_read {
    font-size: 22px;
  }
}

@media screen and (max-width: 767px) {
  .p-mv_read {
    font-size: 22px;
  }
}

@media screen and (max-width: 767px) {
  .p-mv_read {
    font-feature-settings: 'palt';
    min-width: 205px;
    width: 63.7%;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 360px) {
  .p-mv_read {
    font-size: 20px;
  }
}

.p-mv_text {
  color: #fff;
  font-size: 16px;
  line-height: 1.7;
  margin-bottom: 15px;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-mv_text {
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  .p-mv_text {
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  .p-mv_text {
    min-width: 205px;
    width: 63.7%;
    margin-bottom: 10px;
  }
}

.p-mv_bar {
  max-width: 450px;
  width: 100%;
  padding: 0 3% 0 10%;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-mv_bar {
    padding: 0 5% 0 0;
  }
}

@media screen and (max-width: 767px) {
  .p-mv_bar {
    padding: 0 5% 0 0;
  }
}

.p-mv_bar--list {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.p-mv_bar li {
  width: 31%;
  padding: 10px 0;
  cursor: pointer;
}

.p-mv_bar li button {
  font-size: 0;
  line-height: 0;
  width: 100%;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.6);
  display: block;
}

.p-mv_bar li.slick-active button {
  position: relative;
}

.p-mv_bar li.slick-active button:before {
  content: '';
  width: 0;
  height: 100%;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  animation: bar 6s linear;
}

@keyframes bar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.p-service_inner {
  padding: 80px 0 40px;
}

@media screen and (max-width: 767px) {
  .p-service_inner {
    padding: 40px 0;
  }
}

.p-service_text {
  font-size: 16px;
  text-align: center;
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .p-service_text {
    font-size: 14px;
    width: 88.8%;
    margin: 0 auto 30px;
    text-align: left;
  }
}

@media screen and (max-width: 767px) {
  .p-service_text .tab {
    display: none;
  }
}

.p-service_list {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  .p-service_list {
    display: block;
  }
}

.p-service_list a:hover {
  opacity: 1;
}

.p-service_list--top {
  width: 100%;
  padding-bottom: 60%;
  margin-bottom: 15px;
  position: relative;
  overflow: hidden;
}

.p-service_list--pic {
  width: 100%;
  height: 100%;
  transition: 0.3s;
  position: absolute;
  top: 0;
  left: 0;
}

.p-service_list--pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-service_list--heading {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  width: 100%;
  padding: 10px 15px;
  background-color: rgba(0, 0, 0, 0.5);
  display: block;
  position: absolute;
  bottom: -24px;
  left: 0;
  transition: 0.3s;
}

@media screen and (max-width: 767px) {
  .p-service_list--heading {
    font-size: 18px;
  }
}

.p-service_list--heading span {
  font-size: 12px;
  margin-top: 3px;
  display: block;
}

.p-service_list--text {
  font-size: 16px;
  padding: 0 15px;
  text-align: justify;
}

.p-service_list--item {
  width: 33.33%;
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .p-service_list--item {
    width: 100%;
    margin-bottom: 30px;
  }
}

.p-service_list--item:not(:nth-child(3n)) {
  border-right: 1px solid #fff;
}

@media screen and (max-width: 767px) {
  .p-service_list--item:not(:nth-child(3n)) {
    border: none;
  }
}

.p-service_list--item:hover .p-service_list--heading {
  bottom: 0;
}

.p-service_list--item:hover .p-service_list--pic {
  transform: scale(1.1);
}

.p-reason_inner {
  padding: 80px 0;
  margin-bottom: 80px;
  background-color: #eff1f3;
}

@media screen and (max-width: 767px) {
  .p-reason_inner {
    padding: 40px 0;
    margin-bottom: 40px;
  }
}

.p-reason_text {
  font-size: 16px;
  text-align: center;
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .p-reason_text {
    font-size: 14px;
    margin-bottom: 30px;
    text-align: left;
  }
}

.p-reason_list {
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .p-reason_list {
    display: block;
    margin-bottom: 30px;
  }
}

.p-reason_list--pic {
  width: 100%;
  padding-bottom: 55.9%;
  position: relative;
  overflow: hidden;
}

.p-reason_list--pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s;
}

.p-reason_list--box {
  padding: 25px;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-reason_list--box {
    padding: 15px;
  }
}

@media screen and (max-width: 767px) {
  .p-reason_list--box {
    padding: 15px;
  }
}

.p-reason_list--heading {
  color: #255096;
  font-size: 22px;
  font-weight: 700;
  font-feature-settings: 'palt';
  margin-bottom: 15px;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-reason_list--heading {
    font-size: 18px;
  }
}

@media screen and (max-width: 767px) {
  .p-reason_list--heading {
    font-size: 18px;
  }
}

.p-reason_list--text {
  font-size: 16px;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-reason_list--text {
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  .p-reason_list--text {
    font-size: 14px;
  }
}

.p-reason_list--item {
  width: 31.7%;
  background-color: #fff;
}

@media screen and (max-width: 767px) {
  .p-reason_list--item {
    width: 100%;
    margin-bottom: 30px;
  }
}

.p-reason_list--item a {
  display: block;
}

.p-reason_list--item a:hover {
  opacity: 1;
}

.p-reason_list--item a:hover .p-reason_list--pic img {
  transform: translate(-50%, -50%) scale(1.1);
}

.p-reason_btn {
  max-width: 330px;
  width: 100%;
  margin: 0 auto;
}

.p-system_inner {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}

.p-system_wrap {
  display: flex;
}

@media screen and (max-width: 767px) {
  .p-system_wrap {
    display: block;
  }
}

.p-system_cont {
  width: 50%;
  padding: 80px 0;
  position: relative;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .p-system_cont {
    width: 100%;
    padding: 40px 0;
  }
}

.p-system_cont:after {
  content: '';
  width: 100%;
  height: 100%;
  background: url("../img/system_pic01.png") no-repeat center center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transition: 0.3s;
}

.p-system_cont.hub:after {
  background: url("../img/hub_pic01.png") no-repeat center center;
  background-size: cover;
}

.p-system_cont:hover:after {
  transform: scale(1.1);
}

.p-system_cont:hover .c-btn_cmn {
  background-position: -68px;
  opacity: 1;
}

.p-system_cont:hover .c-btn_cmn:after {
  width: 100%;
}

.p-system_textBox {
  position: relative;
  z-index: 3;
}

.p-system_text {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 25px;
}

@media screen and (max-width: 767px) {
  .p-system_text {
    font-size: 14px;
  }
}

.p-system_btn {
  max-width: 365px;
  width: 94%;
  margin: 0 auto;
}

.p-case_inner {
  padding: 80px 0;
}

@media screen and (max-width: 767px) {
  .p-case_inner {
    padding: 60px 0;
  }
}

.p-case_text {
  font-size: 16px;
  text-align: center;
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .p-case_text {
    font-size: 14px;
    text-align: left;
    margin-bottom: 30px;
  }
}

.p-case_list {
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .p-case_list {
    margin-bottom: 30px;
    display: block;
  }
}

.p-case_list--pic {
  margin-bottom: 20px;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .p-case_list--pic {
    margin-bottom: 15px;
  }
}

.p-case_list--pic img {
  transition: 0.3s;
  height: auto;
}

.p-case_list--heading {
  color: #255096;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .p-case_list--heading {
    font-size: 16px;
    margin-bottom: 5px;
  }
}

.p-case_list--text {
  font-size: 16px;
}

@media screen and (max-width: 767px) {
  .p-case_list--text {
    font-size: 14px;
  }
}

.p-case_list--item {
  width: 31.7%;
}

@media screen and (max-width: 767px) {
  .p-case_list--item {
    width: 100%;
    margin-bottom: 30px;
  }
}

.p-case_list--item:hover .p-case_list--pic img {
  transform: scale(1.1);
}

.p-case_list--item a:hover {
  opacity: 1;
}

.p-case_btn {
  max-width: 365px;
  width: 100%;
  margin: 0 auto;
}

.p-link_inner {
  padding: 80px 0;
  background-color: #eff1f3;
}

@media screen and (max-width: 767px) {
  .p-link_inner {
    padding: 40px 0;
  }
}

.p-link_list {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .p-link_list {
    display: block;
  }
}

.p-link_list--item {
  width: 31.7%;
}

@media screen and (max-width: 767px) {
  .p-link_list--item {
    width: 100%;
  }
}

.p-link_list--item:not(:last-child) {
  margin-bottom: 20px;
}

.p-link_list--item a {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  padding: 140px 15px 10px;
  display: block;
  position: relative;
  overflow: hidden;
}

.p-link_list--item a:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.3s;
}

.p-link_list--item span {
  position: relative;
}

.p-link_list--item:hover a {
  opacity: 1;
}

.p-link_list--item:hover a:before {
  transform: scale(1.1);
}

.p-link_list--item.company a:before {
  background: url("../img/company_pic01.jpg") no-repeat center;
  background-size: cover;
}

.p-link_list--item.recruit a:before {
  background: url("../img/recruit_pic01.jpg") no-repeat center;
  background-size: cover;
}

.p-link_list--item.group a:before {
  background: url("../img/group_pic01.jpg") no-repeat center;
  background-size: cover;
}

.p-news_inner {
  padding: 80px 0 155px;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-news_inner {
    padding: 80px 0;
  }
}

@media screen and (max-width: 767px) {
  .p-news_inner {
    padding: 80px 0;
  }
}

@media screen and (max-width: 767px) {
  .p-news_inner {
    padding: 40px 0 60px;
  }
}

.p-news_list {
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .p-news_list {
    margin-bottom: 30px;
  }
}

.p-news_list--item {
  border-bottom: 1px solid #a7b9d5;
}

.p-news_list--item:first-child {
  border-top: 1px solid #a7b9d5;
}

.p-news_list--item a {
  padding: 25px 15px;
  transition: 0.3s;
}

@media screen and (max-width: 767px) {
  .p-news_list--item a {
    padding: 15px 10px;
  }
}

.p-news_list--item a:hover {
  background-color: rgba(37, 80, 150, 0.1);
  opacity: 1;
}

.p-news_list--wrap {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .p-news_list--wrap {
    display: block;
  }
}

.p-news_list--left {
  width: 230px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .p-news_list--left {
    width: 100%;
    margin-bottom: 10px;
  }
}

.p-news_list--right {
  width: calc( 100% - 230px);
}

@media screen and (max-width: 767px) {
  .p-news_list--right {
    width: 100%;
  }
}

.p-news_list--date {
  font-size: 16px;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  .p-news_list--date {
    font-size: 14px;
  }
}

.p-news_list--category {
  color: #fff;
  font-size: 14px;
  line-height: 1;
  padding: 5px 15px 6px;
  margin-left: 35px;
  background-color: #255096;
  display: block;
}

@media screen and (max-width: 767px) {
  .p-news_list--category {
    margin-left: 20px;
  }
}

.p-news_list--title {
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.p-news_btn {
  max-width: 365px;
  width: 100%;
  margin: 0 auto;
}

.p-news_pagenation {
  margin-top: 80px;
}

@media screen and (max-width: 767px) {
  .p-news_pagenation {
    margin-top: 40px;
  }
}

.p-news_detail--date {
  font-size: 16px;
  margin-bottom: 25px;
}

@media screen and (max-width: 767px) {
  .p-news_detail--date {
    font-size: 14px;
    margin-bottom: 20px;
  }
}

.p-news_detail--inner {
  padding-top: 60px;
  border-top: 1px solid rgba(37, 80, 150, 0.4);
}

@media screen and (max-width: 767px) {
  .p-news_detail--inner {
    padding-top: 30px;
  }
}

.p-news_detail--wrap {
  max-width: 1040px;
  width: 100%;
  margin: 0 auto;
  line-height: 2;
}

.p-news_detail--wrap h1 {
  color: #255096;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 25px;
}

@media screen and (max-width: 767px) {
  .p-news_detail--wrap h1 {
    font-size: 22px;
    margin-bottom: 15px;
  }
}

.p-news_detail--wrap h2 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 15px;
}

.p-news_detail--wrap p {
  font-size: 16px;
}

@media screen and (max-width: 767px) {
  .p-news_detail--wrap p {
    font-size: 14px;
  }
}

.p-news_detail--wrap a {
  color: #255096;
  text-decoration: underline;
}

.p-group_inner {
  padding: 80px 0;
  background-color: #eff1f3;
}

@media screen and (max-width: 767px) {
  .p-group_inner {
    padding: 40px 0;
  }
}

.p-group_list {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .p-group_list {
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.p-group_list--item {
  width: 23.4%;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-group_list--item {
    width: 32%;
  }
}

@media screen and (max-width: 767px) {
  .p-group_list--item {
    width: 32%;
  }
}

@media screen and (max-width: 767px) {
  .p-group_list--item {
    width: 48%;
    margin-bottom: 10px;
  }
}

.p-group_list--item:not(:nth-child(3n)) {
  margin-right: 3.2%;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-group_list--item:not(:nth-child(3n)) {
    margin-right: 2%;
  }
}

@media screen and (max-width: 767px) {
  .p-group_list--item:not(:nth-child(3n)) {
    margin-right: 2%;
  }
}

@media screen and (max-width: 767px) {
  .p-group_list--item:not(:nth-child(3n)) {
    margin-right: 0;
  }
}

.p-group_list--item img {
  width: 100%;
}

.p-group_list--ttl {
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-group_list--ttl {
    font-size: 12px;
  }
}

@media screen and (max-width: 767px) {
  .p-group_list--ttl {
    font-size: 12px;
  }
}

@media screen and (max-width: 767px) {
  .p-group_list--ttl {
    margin-top: 5px;
  }
}

.p-network_hdqrs {
  margin-bottom: 60px;
}

@media screen and (max-width: 767px) {
  .p-network_hdqrs {
    margin-bottom: 40px;
  }
}

.p-network_hdqrs--inner {
  padding: 60px 6%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .p-network_hdqrs--inner {
    padding: 30px 4%;
    display: block;
  }
}

.p-network_hdqrs--img {
  max-width: 592px;
  width: 58%;
}

@media screen and (max-width: 767px) {
  .p-network_hdqrs--img {
    max-width: none;
    width: 100%;
    margin-bottom: 20px;
  }
}

.p-network_hdqrs--textBox {
  max-width: 364px;
  width: 35.6%;
}

@media screen and (max-width: 767px) {
  .p-network_hdqrs--textBox {
    max-width: none;
    width: 100%;
    padding: 0 15px;
  }
}

.p-network_hdqrs--textBox h3 {
  margin-bottom: 25px;
}

@media screen and (max-width: 767px) {
  .p-network_hdqrs--textBox h3 {
    margin-bottom: 15px;
  }
}

.p-network_hdqrs--text {
  margin-bottom: 45px;
}

@media screen and (max-width: 767px) {
  .p-network_hdqrs--text {
    margin-bottom: 25px;
  }
}

.p-network_hdqrs--text a {
  display: inline-block;
}

.p-network_office ul {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-network_office ul {
    justify-content: space-between;
  }
}

@media screen and (max-width: 767px) {
  .p-network_office ul {
    justify-content: space-between;
  }
}

@media screen and (max-width: 767px) {
  .p-network_office ul {
    display: block;
  }
}

.p-network_office ul li {
  max-width: 368px;
  width: 32%;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-network_office ul li {
    max-width: none;
    width: 48%;
  }
}

@media screen and (max-width: 767px) {
  .p-network_office ul li {
    max-width: none;
    width: 48%;
  }
}

@media screen and (max-width: 767px) {
  .p-network_office ul li {
    width: 100%;
    margin-bottom: 30px;
  }
}

.p-network_office ul li:not(:nth-child(3n)) {
  margin-right: 2%;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-network_office ul li:not(:nth-child(3n)) {
    margin-right: 0;
  }
}

@media screen and (max-width: 767px) {
  .p-network_office ul li:not(:nth-child(3n)) {
    margin-right: 0;
  }
}

.p-network_office--top {
  margin-bottom: 10px;
  position: relative;
}

.p-network_office h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  padding: 15px;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-network_office h3 {
    font-size: 18px;
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .p-network_office h3 {
    font-size: 18px;
    padding: 10px;
  }
}

.p-network_office--text {
  font-size: 16px;
  line-height: 1.625;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .p-network_office--text {
    font-size: 14px;
  }
}

.p-network_office--text span {
  display: inline-block;
}

.p-network_office--text a {
  display: inline-block;
}

.p-network_office--text .bold {
  display: block;
}

.p-network_office--btnWrap {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.p-network_office--btn {
  width: 48%;
}

@media screen and (max-width: 767px) {
  .p-network_office--btn .c-btn_map,
  .p-network_office--btn .c-btn_modal {
    max-width: none;
  }
}

.p-network_office--img img {
  width: 100%;
}

.p-network_relay {
  padding: 40px 4%;
}

@media screen and (max-width: 767px) {
  .p-network_relay {
    padding: 30px 4%;
  }
}

.p-network_relay h3 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .p-network_relay h3 {
    font-size: 18px;
    margin-bottom: 20px;
  }
}

.p-network_relay--list {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .p-network_relay--list {
    display: block;
  }
}

.p-network_relay ul {
  font-size: 16px;
}

@media screen and (max-width: 767px) {
  .p-network_relay ul {
    font-size: 14px;
  }
}

.p-network_relay ul li {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .p-network_relay ul li {
    display: block;
  }
}

.p-network_relay ul li:not(:last-child) {
  margin-bottom: 15px;
}

.p-network_relay ul span {
  color: #255096;
  width: 124px;
  padding: 6px 0 7px;
  margin-right: 35px;
  border: 1px solid #255096;
  background-color: #fff;
  display: block;
}

@media screen and (max-width: 767px) {
  .p-network_relay ul span {
    width: 110px;
    margin: 0 0 5px;
    padding: 3px 0 4px;
  }
}

.p-network_cont:first-of-type {
  margin-bottom: 85px;
}

@media screen and (max-width: 767px) {
  .p-network_cont:first-of-type {
    margin-bottom: 55px;
  }
}

.p-network_modal .p-network_hdqrs--inner {
  padding: 0;
  margin-bottom: 25px;
}

@media screen and (max-width: 767px) {
  .p-network_modal .p-network_hdqrs--text {
    font-size: 14px;
  }
}

.p-network_modal--text {
  font-size: 16px;
  line-height: 1.625;
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .p-network_modal--text {
    font-size: 14px;
    margin-bottom: 30px;
  }
}

.p-network_modal--list {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .p-network_modal--list {
    flex-wrap: wrap;
  }
}

.p-network_modal--item {
  max-width: 317px;
  width: 31%;
}

@media screen and (max-width: 767px) {
  .p-network_modal--item {
    max-width: none;
    width: 48%;
    margin-bottom: 15px;
  }
}

.p-network_modal--pic {
  margin-bottom: 24px;
}

@media screen and (max-width: 767px) {
  .p-network_modal--pic {
    margin-bottom: 10px;
  }
}

.p-network_modal--listText {
  font-size: 16px;
}

@media screen and (max-width: 767px) {
  .p-network_modal--listText {
    font-size: 14px;
  }
}

.p-greeting {
  margin-bottom: 80px;
}

@media screen and (max-width: 767px) {
  .p-greeting {
    margin-bottom: 60px;
  }
}

.p-greeting_cont {
  padding: 40px;
}

@media screen and (max-width: 767px) {
  .p-greeting_cont {
    padding: 25px;
  }
}

.p-greeting_wrap {
  display: flex;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-greeting_wrap {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .p-greeting_wrap {
    display: block;
  }
}

.p-greeting_pic {
  width: 32%;
  margin-right: 4%;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-greeting_pic {
    margin: 0 auto 30px;
  }
}

@media screen and (max-width: 767px) {
  .p-greeting_pic {
    margin: 0 auto 30px;
  }
}

@media screen and (max-width: 767px) {
  .p-greeting_pic {
    width: 100%;
    text-align: center;
  }
}

.p-greeting_textBox {
  width: 64%;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-greeting_textBox {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .p-greeting_textBox {
    width: 100%;
  }
}

.p-greeting_text {
  font-size: 18px;
  line-height: 1.7;
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .p-greeting_text {
    font-size: 14px;
    margin-bottom: 20px;
  }
}

.p-greeting_ceo {
  font-size: 22px;
  font-weight: 500;
}

@media screen and (max-width: 767px) {
  .p-greeting_ceo {
    font-size: 18px;
  }
}

.p-profile {
  margin-bottom: 80px;
}

@media screen and (max-width: 767px) {
  .p-profile {
    margin-bottom: 60px;
  }
}

.p-company_inner {
  padding: 80px 0;
}

@media screen and (max-width: 767px) {
  .p-company_inner {
    padding: 60px 0;
  }
}

.p-company_inner.gray {
  background-color: #eff1f3;
}

.p-company_cont {
  padding: 60px 0;
}

@media screen and (max-width: 767px) {
  .p-company_cont {
    padding: 30px 0;
  }
}

.p-company_text {
  color: #255096;
  font-size: 28px;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-company_text {
    font-size: 22px;
  }
}

@media screen and (max-width: 767px) {
  .p-company_text {
    font-size: 22px;
  }
}

@media screen and (max-width: 767px) {
  .p-company_text {
    font-size: 16px;
  }
}

.p-company_vision {
  padding: 80px 0;
  margin-bottom: 80px;
  background: url("../img/company/bg_index01.jpg") no-repeat center;
  background-size: cover;
}

@media screen and (max-width: 767px) {
  .p-company_vision {
    padding: 40px 0;
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) {
  .p-company_vision--cont:first-child {
    margin-bottom: 40px;
  }
}

.p-company_vision--box {
  background-color: rgba(255, 255, 255, 0.8);
}

.p-company_vision--text {
  color: #255096;
  font-size: 28px;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  padding: 40px 0;
}

@media screen and (max-width: 1150px) and (min-width: 901px) {
  .p-company_vision--text {
    font-size: 22px;
  }
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-company_vision--text {
    font-size: 18px;
  }
}

@media screen and (max-width: 767px) {
  .p-company_vision--text {
    font-size: 18px;
  }
}

@media screen and (max-width: 767px) {
  .p-company_vision--text {
    font-size: 16px;
    padding: 30px 0;
  }
}

.p-company_plan {
  margin-bottom: 80px;
}

@media screen and (max-width: 767px) {
  .p-company_plan {
    margin-bottom: 60px;
  }
}

.p-company_plan--wrap {
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .p-company_plan--wrap {
    margin-bottom: 30px;
  }
}

.p-company_plan--box {
  background-color: rgba(239, 241, 243, 0.3);
}

@media screen and (max-width: 767px) {
  .p-company_plan--box:first-child {
    margin-bottom: 30px;
  }
}

.p-company_plan--ttl {
  color: #255096;
  font-size: 18px;
  font-weight: bold;
  padding: 20px 0;
  background-color: #eff1f3;
}

.p-company_plan--textBox {
  height: calc( 100% - 68px);
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .p-company_plan--textBox {
    padding: 30px 0;
  }
}

.p-company_plan--read {
  color: #255096;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 5px;
}

@media screen and (max-width: 1150px) {
  .p-company_plan--read {
    font-size: 18px;
  }
}

.p-company_plan--text {
  font-size: 22px;
  font-weight: bold;
  font-feature-settings: 'palt';
  text-align: center;
}

@media screen and (max-width: 1150px) {
  .p-company_plan--text {
    font-size: 18px;
  }
}

.p-company_plan--text .line {
  background: linear-gradient(transparent 60%, #E8ECF3 60%);
}

.p-company_plan--text .orange {
  color: #FF9140;
}

.p-company_policy {
  max-width: 793px;
  width: 100%;
  margin: 0 auto;
  padding: 40px 0;
}

@media screen and (max-width: 767px) {
  .p-company_policy {
    padding: 30px 0;
  }
}

.p-company_policy--ttl {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .p-company_policy--ttl {
    font-size: 18px;
    margin-bottom: 20px;
  }
}

.p-company_policy--wrap {
  display: flex;
  justify-content: center;
}

.p-company_policy--list {
  font-size: 20px;
  line-height: 1.8;
}

@media screen and (max-width: 767px) {
  .p-company_policy--list {
    font-size: 16px;
  }
}

.p-company_policy--list li {
  text-indent: -1em;
  padding-left: 1em;
  font-feature-settings: 'palt';
  letter-spacing: 1px;
}

.p-company_policy--list li:not(:last-child) {
  margin-bottom: 10px;
}

.p-companyConcept_inner {
  padding: 100px 0 80px;
}

@media screen and (max-width: 767px) {
  .p-companyConcept_inner {
    padding: 60px 0;
  }
}

.p-companyConcept_ttl {
  font-size: 22px;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  .p-companyConcept_ttl {
    font-size: 18px;
  }
}

.p-companyConcept_img {
  max-width: 793px;
  width: 100%;
  margin: 40px auto 0;
}

@media screen and (max-width: 767px) {
  .p-companyConcept_img {
    margin-top: 30px;
  }
}

.p-companyConcept_box {
  max-width: 793px;
  width: 100%;
  margin: 60px auto 0;
  padding: 40px 5% 25px;
  background-color: #EFF1F3;
}

@media screen and (max-width: 767px) {
  .p-companyConcept_box {
    margin-top: 40px;
    padding: 30px 5% 20px;
  }
}

.p-companyConcept_box--ttl {
  font-size: 22px;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  .p-companyConcept_box--ttl {
    font-size: 18px;
  }
}

.p-companyConcept_boxList {
  margin-top: 10px;
}

.p-companyConcept_boxList--item {
  font-size: 18px;
  position: relative;
  padding: 25px 4.2%;
  border-bottom: 1px solid #255096;
}

@media screen and (max-width: 767px) {
  .p-companyConcept_boxList--item {
    font-size: 14px;
    padding: 15px 4%;
  }
}

.p-companyConcept_boxList--text {
  padding-left: 50px;
  position: relative;
}

@media screen and (max-width: 767px) {
  .p-companyConcept_boxList--text {
    padding-left: 30px;
  }
}

.p-companyConcept_boxList--text span {
  color: #255096;
  position: absolute;
  top: 0;
  left: 0;
}

.p-history_inner {
  padding: 80px 0;
  background-color: #eff1f3;
}

@media screen and (max-width: 767px) {
  .p-history_inner {
    padding: 60px 0;
  }
}

.p-history_cont {
  padding: 60px;
}

@media screen and (max-width: 767px) {
  .p-history_cont {
    padding: 25px 3%;
  }
}

.p-history_table th {
  color: #222;
  font-size: 16px;
  font-weight: normal;
  padding: 23px 50px;
}

@media screen and (max-width: 767px) {
  .p-history_table th {
    font-size: 12px;
    padding: 15px 20px;
  }
}

.p-history_table a {
  color: #255096;
  font-size: 18px;
  display: inline;
  text-decoration: underline;
}

@media screen and (max-width: 767px) {
  .p-history_table a {
    font-size: 14px;
  }
}

.p-grouppage .p-network_hdqrs--textBox h3 {
  font-size: 18px;
}

.p-grouppage_office--top {
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .p-grouppage_office--top {
    margin-bottom: 15px;
  }
}

.p-grouppage_office--text {
  margin-bottom: 25px;
}

@media screen and (max-width: 767px) {
  .p-grouppage_office--text {
    margin-bottom: 20px;
  }
}

.p-grouppage_office h3 {
  color: #222;
  font-size: 18px;
  padding: 0 !important;
  margin-bottom: 10px;
  position: static;
}

@media screen and (max-width: 1200px) {
  .p-grouppage .c-btn_cmn {
    font-size: 13px;
  }
}

.p-privacy_inner {
  max-width: 1040px;
  width: 88.8%;
  margin: 0 auto;
}

.p-privacy_cont:not(:last-child) {
  margin-bottom: 60px;
}

@media screen and (max-width: 767px) {
  .p-privacy_cont:not(:last-child) {
    margin-bottom: 40px;
  }
}

.p-privacy_text {
  font-size: 16px;
  font-weight: 500;
  line-height: 2;
}

@media screen and (max-width: 767px) {
  .p-privacy_text {
    font-size: 14px;
  }
}

.p-privacy_title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 25px;
  text-indent: -0.9em;
  padding-left: 0.9em;
}

@media screen and (max-width: 767px) {
  .p-privacy_title {
    font-size: 16px;
    margin-bottom: 15px;
  }
}

.p-privacy_list {
  margin-top: 25px;
}

@media screen and (max-width: 767px) {
  .p-privacy_list {
    margin-top: 15px;
  }
}

.p-privacy_list li {
  font-size: 16px;
  font-feature-settings: 'palt';
  font-weight: 500;
  text-indent: -1.6em;
  padding-left: 1.6em;
}

@media screen and (max-width: 767px) {
  .p-privacy_list li {
    font-size: 14px;
  }
}

.p-privacy_list li:not(:last-child) {
  margin-bottom: 10px;
}

.p-privacy_box {
  padding-left: 20px;
}

@media screen and (max-width: 767px) {
  .p-privacy_box {
    padding-left: 10px;
  }
}

.p-privacy_box:not(:last-of-type) {
  margin-bottom: 40px;
}

.p-privacy_box .p-privacy_text {
  padding-left: 20px;
}

@media screen and (max-width: 767px) {
  .p-privacy_box .p-privacy_text {
    padding-left: 10px;
  }
}

.p-privacy_box .p-privacy_text:not(:last-of-type) {
  margin-bottom: 15px;
}

.p-privacy_box .p-privacy_list {
  padding-left: 20px;
}

@media screen and (max-width: 767px) {
  .p-privacy_box .p-privacy_list {
    padding-left: 10px;
  }
}

.p-privacy_box ul {
  margin-bottom: 20px;
}

.p-privacy_box a {
  color: #255096;
  font-size: 16px;
  font-weight: 500;
  display: inline;
  text-decoration: underline;
}

@media screen and (max-width: 767px) {
  .p-privacy_box a {
    font-size: 14px;
  }
}

.p-privacy_caption {
  font-size: 16px;
  font-weight: bold;
  margin-top: 25px;
  padding-left: 20px;
  line-height: 2;
}

@media screen and (max-width: 767px) {
  .p-privacy_caption {
    font-size: 14px;
    padding-left: 10px;
  }
}

.p-privacy_caption span {
  text-indent: -3em;
  padding-left: 3em;
  display: block;
}

.p-contact_text {
  font-size: 16px;
  font-weight: 500;
  line-height: 2;
  margin-bottom: 60px;
}

@media screen and (max-width: 767px) {
  .p-contact_text {
    font-size: 14px;
    text-align: left;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) {
  .p-contact_text .tab {
    display: none;
  }
}

.p-contact_flow {
  display: flex;
  border: 1px solid #255096;
  overflow: hidden;
  margin-bottom: 45px;
}

@media screen and (max-width: 767px) {
  .p-contact_flow {
    margin-bottom: 30px;
  }
}

.p-contact_flow span {
  color: #255096;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  padding: 11px 0 13px;
  display: block;
  transform: skew(-30deg, 0deg);
  -webkit-transform: skew(-30deg, 0deg);
}

@media screen and (max-width: 767px) {
  .p-contact_flow span {
    font-size: 14px;
  }
}

.p-contact_flow .current {
  position: relative;
}

.p-contact_flow .current:before {
  content: '';
  width: calc( 100% + 15px);
  height: 100%;
  background-color: #255096;
  position: absolute;
  top: 0;
  left: -15px;
}

.p-contact_flow .current span {
  color: #fff;
}

.p-contact_flow li {
  width: calc( 100% / 3);
  transform: skew(30deg, 0deg);
  -webkit-transform: skew(30deg, 0deg);
}

.p-contact_flow li:not(:last-child) {
  border-right: 1px solid #255096;
}

.p-contact_flow li:nth-child(2).current {
  background-color: #255096;
}

.p-contact_flow li:nth-child(2).current:before {
  display: none;
}

.p-contact_flow li:last-child.current:before {
  left: auto;
  right: -15px;
}

.p-contact_form--text {
  font-size: 16px;
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .p-contact_form--text {
    font-size: 14px;
    margin-bottom: 30px;
  }
}

.p-contact_form--text span {
  margin-right: 5px;
}

.p-contact_required {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  width: 54px;
  padding: 1px 0 2px;
  background-color: #255096;
  text-align: center;
  display: inline-block;
}

.p-contact_privacy {
  margin-bottom: 40px;
}

.p-contact_privacy .mwform-checkbox-field-text {
  font-size: 16px;
  margin-right: 0;
}

.p-contact_btn {
  max-width: 330px;
  width: 100%;
  margin: 0 auto;
}

.p-contact_btnWrap {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .p-contact_btnWrap {
    display: block;
  }
}

.p-contact_btnWrap .p-contact_btn {
  margin: 0;
}

@media screen and (max-width: 767px) {
  .p-contact_btnWrap .p-contact_btn {
    margin: 0 auto;
  }
}

.p-contact_btnWrap .p-contact_btn:not(:last-child) {
  margin-right: 25px;
}

@media screen and (max-width: 767px) {
  .p-contact_btnWrap .p-contact_btn:not(:last-child) {
    margin: 0 auto 20px;
  }
}

.p-contact_thanks {
  padding: 60px 0;
  background-color: #e8ecf3;
}

@media screen and (max-width: 767px) {
  .p-contact_thanks {
    padding: 40px 20px;
  }
}

.p-contact_thanks--read {
  margin-bottom: 25px;
}

@media screen and (max-width: 767px) {
  .p-contact_thanks--read {
    font-feature-settings: 'palt';
    margin-bottom: 15px;
  }
}

.p-contact_thanks--text {
  line-height: 2;
}

@media screen and (max-width: 767px) {
  .p-contact_thanks--text {
    font-size: 14px;
    text-align: left;
  }
}

.p-contact .c-table_form.confirm .p-contact_required {
  display: none;
}

.p-contact .mw_wp_form_confirm .p-contact_privacy {
  display: none;
}

.p-works_list {
  margin-bottom: 80px;
}

@media screen and (max-width: 767px) {
  .p-works_list {
    margin-bottom: 40px;
  }
}

.p-works_box {
  border-bottom: 1px solid rgba(37, 80, 150, 0.4);
}

.p-works_box:first-child {
  border-top: 1px solid rgba(37, 80, 150, 0.4);
}

.p-works_box a {
  padding: 40px 0;
}

@media screen and (max-width: 767px) {
  .p-works_box a {
    padding: 25px 0;
  }
}

.p-works_wrap {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .p-works_wrap {
    display: block;
  }
}

.p-works_pic {
  max-width: 350px;
  width: 30%;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .p-works_pic {
    max-width: none;
    width: 100%;
    margin-bottom: 25px;
  }
}

.p-works_pic--inner {
  width: 100%;
  padding-bottom: 60%;
  background-size: cover;
  background-position: center center;
  position: relative;
  transition: 0.3s;
}

.p-works_textBox {
  max-width: 770px;
  width: 67%;
}

@media screen and (max-width: 767px) {
  .p-works_textBox {
    max-width: none;
    width: 100%;
  }
}

.p-works_date {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
}

@media screen and (max-width: 767px) {
  .p-works_date {
    font-size: 12px;
    margin-bottom: 5px;
  }
}

.p-works_title {
  color: #255096;
  font-size: 22px;
  margin-bottom: 25px;
}

@media screen and (max-width: 767px) {
  .p-works_title {
    font-size: 16px;
    margin-bottom: 15px;
  }
}

.p-works_text {
  font-size: 18px;
  line-height: 1.7;
}

@media screen and (max-width: 767px) {
  .p-works_text {
    font-size: 14px;
  }
}

.p-works_text .more {
  color: #255096;
  margin-left: 25px;
  text-decoration: underline;
}

.p-works_box a:hover {
  opacity: 1;
}

.p-works_box a:hover .p-works_pic--inner {
  transform: scale(1.1);
}

.p-servicepage_detail {
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .p-servicepage_intro--read {
    text-align: left;
  }
}

.p-servicepage_worry {
  margin-bottom: 80px;
}

@media screen and (max-width: 767px) {
  .p-servicepage_worry {
    margin-bottom: 60px;
  }
}

.p-servicepage_worry--box {
  max-width: 793px;
  width: 100%;
  margin: 0 auto;
  padding: 40px;
}

@media screen and (max-width: 767px) {
  .p-servicepage_worry--box {
    padding: 30px 20px;
  }
}

.p-servicepage_worry--read {
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .p-servicepage_worry--read {
    margin-bottom: 30px;
  }
}

.p-servicepage_worry--list {
  display: flex;
  justify-content: center;
}

.p-servicepage_point {
  margin-bottom: 80px;
}

@media screen and (max-width: 767px) {
  .p-servicepage_point {
    margin-bottom: 60px;
  }
}

.p-servicepage_point--text {
  margin-bottom: 15px;
}

.p-servicepage_point--read {
  margin-bottom: 40px;
}

.p-servicepage_faq {
  margin-bottom: 80px;
}

@media screen and (max-width: 767px) {
  .p-servicepage_faq {
    margin-bottom: 60px;
  }
}

.p-servicepage_service {
  padding: 0;
}

.u-mb_10 {
  margin-bottom: 10px !important;
}

.u-mb_15 {
  margin-bottom: 15px !important;
}

@media screen and (max-width: 767px) {
  .u-mb_15 {
    margin-bottom: 10px !important;
  }
}

.u-mb_20 {
  margin-bottom: 20px !important;
}

@media screen and (max-width: 767px) {
  .u-mb_20 {
    margin-bottom: 10px !important;
  }
}

.u-mb_30 {
  margin-bottom: 30px !important;
}

@media screen and (max-width: 767px) {
  .u-mb_30 {
    margin-bottom: 20px !important;
  }
}

.u-mb_40 {
  margin-bottom: 40px !important;
}

@media screen and (max-width: 767px) {
  .u-mb_40 {
    margin-bottom: 25px !important;
  }
}

.u-mb_45 {
  margin-bottom: 45px !important;
}

.u-mt_10 {
  margin-top: 10px !important;
}

.u-p_0 {
  padding: 0 !important;
}

.u-w_49 {
  width: 49%;
}

.u-indent_0 {
  text-indent: 0 !important;
  padding-left: 0 !important;
}

.u-indent_0-5 {
  text-indent: -0.5em !important;
  padding-left: 0.5em !important;
}

.u-indent_1 {
  text-indent: -1em !important;
  padding-left: 1em !important;
}

.u-indent_1-3 {
  text-indent: -1.3em !important;
  padding-left: 1.3em !important;
}

.u-indent_1-8 {
  text-indent: -1.8em;
  padding-left: 1.8em;
  display: inline-block;
}
