.p-network {
  &_hdqrs {
    margin-bottom: 60px;
    @include sp {
      margin-bottom: 40px;
    }
    &--inner {
      padding: 60px 6%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include sp {
        padding: 30px 4%;
        display: block;
      }
    }

    &--img {
      max-width: 592px;
      width: 58%;
      @include sp {
        max-width: none;
        width: 100%;
        margin-bottom: 20px;
      }
    }

    &--textBox {
      max-width: 364px;
      width: 35.6%;
      @include sp {
        max-width: none;
        width: 100%;
        padding: 0 15px;
      }

      h3 {
        margin-bottom: 25px;
        @include sp {
          margin-bottom: 15px;
        }
      }
    }

    &--text {
      margin-bottom: 45px;
      @include sp {
        margin-bottom: 25px;
      }
      a {
        display: inline-block;
      }
    }
  }

  &_office {
    ul {
      display: flex;
      flex-wrap: wrap;
      @include tab {
        justify-content: space-between;
      }
      @include sp {
        display: block;
      }
      li {
        max-width: 368px;
        width: 32%;
        margin-bottom: 40px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        @include tab {
          max-width: none;
          width: 48%;
        }
        @include sp {
          width: 100%;
          margin-bottom: 30px;
        }
        &:not(:nth-child(3n)) {
          margin-right: 2%;
          @include tab {
            margin-right: 0;
          }
        }
      }
    }

    &--top {
      margin-bottom: 10px;
      position: relative;
    }

    h3 {
      color: $white;
      font-size: 20px;
      font-weight: 500;
      padding: 15px;
      position: absolute;
      bottom: 0;
      left: 0;
      @include tab {
        font-size: 18px;
        padding: 10px;
      }
    }

    &--text {
      font-size: 16px;
      line-height: 1.625;
      margin-bottom: 10px;
      @include sp {
        font-size: 14px;
      }

      span {
        display: inline-block;
      }

      a {
        display: inline-block;
      }

      .bold {
        display: block;
      }
    }

    &--btnWrap {
      display: flex;
      justify-content: space-between;
      position: relative;
    }

    &--btn {
      width: 48%;

      .c-btn_map,
      .c-btn_modal {
        @include sp {
          max-width: none;
        }
      }
    }

    &--img {
      img {
        width: 100%;
      }
    }
  }

  &_relay {
    padding: 40px 4%;
    @include sp {
      padding: 30px 4%;
    }

    h3 {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 30px;
      @include sp {
        font-size: 18px;
        margin-bottom: 20px;
      }
    }

    &--list {
      display: flex;
      justify-content: center;
      @include sp {
        display: block;
      }
    }

    ul {
      font-size: 16px;
      @include sp {
        font-size: 14px;
      }

      li {
        display: flex;
        align-items: center;
        @include sp {
          display: block;
        }
        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
      
      span {
        color: $blue;
        width: 124px;
        padding: 6px 0 7px;
        margin-right: 35px;
        border: 1px solid $blue;
        background-color: $white;
        display: block;
        @include sp {
          width: 110px;
          margin: 0 0 5px;
          padding: 3px 0 4px;
        }
      }
    }
  }

  &_cont {
    &:first-of-type {
      margin-bottom: 85px;
      @include sp {
        margin-bottom: 55px;
      }
    }
  }

  &_modal {
    .p-network {
      &_hdqrs {
        &--inner {
          padding: 0;
          margin-bottom: 25px;
        }

        &--text {
          @include sp {
            font-size: 14px;
          }
        }
      }
    }

    &--text {
      font-size: 16px;
      line-height: 1.625;
      margin-bottom: 40px;
      @include sp {
        font-size: 14px;
        margin-bottom: 30px;
      }
    }

    &--list {
      display: flex;
      justify-content: space-between;
      @include sp {
        flex-wrap: wrap;
      }
    }

    &--item {
      max-width: 317px;
      width: 31%;
      @include sp {
        max-width: none;
        width: 48%;
        margin-bottom: 15px;
      }
    }

    &--pic {
      margin-bottom: 24px;
      @include sp {
        margin-bottom: 10px;
      }
    }

    &--listText {
      font-size: 16px;
      @include sp {
        font-size: 14px;
      }
    }
  }
}