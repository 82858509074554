.c-table {
  margin-bottom: 100px;
  @include sp {
    margin-bottom: 60px;
  }
  &_cmn {
    tr {
      border-bottom: 1px solid rgba( $blue, 0.4);

      &:first-child {
        border-top: 1px solid rgba( $blue, 0.4);
      }
    }

    th,td {
      font-size: 18px;
      padding: 23px 0;
      line-height: 1.7;
      @include sp {
        font-size: 14px;
      }
    }

    th {
      color: $blue;
      font-weight: bold;
      width: 200px;
      text-align: left;
      padding: 23px 60px;
      @include sp {
        width: 120px;
        padding: 15px 30px;
      }
    }

    td {
      width: calc( 100% - 200px);
      @include sp {
        width: calc( 100% - 120px);
      }
    }
  }

  &_form {
    width: 100%;
    margin-bottom: 40px;
    tr {
      border-bottom: 1px solid rgba( $blue, 0.4);
      @include sp {
        padding: 20px 10px;
        display: block;
      }

      &:first-child {
        border-top: 1px solid rgba( $blue, 0.4);
      }

      &:last-child {
        display: none;
      }
    }

    th {
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      width: 250px;
      padding: 25px 0 25px 40px;
      vertical-align: middle;
      @include tab {
        width: 220px;
        padding-left: 20px;
      }
      @include sp {
        width: 100%;
        display: block;
        padding: 0;
        margin-bottom: 15px;
      }

      .flex {
        width: 100%;
        display: flex;
        justify-content: space-between;
        @include sp {
          justify-content: flex-start;
        }
      }

      span {
        @include sp {
          margin-left: 15px;
        }
      }

      &.verticalTop {
        padding-top: 30px;
        vertical-align: top;
        @include sp {
          padding: 0;
        }
      }
    }

    td {
      width: calc( 100% - 250px );
      padding: 25px 40px;
      @include tab {
        width: calc( 100% - 220px );
        padding: 25px 20px;
      }
      @include sp {
        width: 100%;
        padding: 0;
        display: block;
      }

      .flex {
        display: block;

        label {
          display: block;
          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }
    }

    &--wrap {
      display: flex;
      justify-content: space-between;
    }

    &--disc {
      font-size: 14px;
      margin-top: 10px;
    }

    &--inputWrap {
      max-width: 550px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      @include sp {
        flex-direction: column;
      }

      .horizontal-item {
        margin: 7.5px 0;
        @include sp {
          margin: 5px 0;
        }
        &+ .horizontal-item {
          margin-left: 0 !important;
        }

        &:not(:last-child) {
          @include sp {
            margin-bottom: 10px;
          }
        }
      }
    }

    &.confirm {
      @include sp {
        font-size: 14px;
      }
      tr {
        @include sp {
          padding: 0;
          border: none;
        }
        &:last-child {
          display: table-row;
        }
      }
      th {
        width: 210px;
        background-color: $lightblue;
        @include sp {
          font-size: 14px;
          width: 100%;
          padding: 10px 15px;
          margin: 0 auto;
        }
      }

      td {
        width: calc( 100% - 210px );
        @include sp {
          width: 100%;
          min-height: 42px;
          padding: 10px 15px;
        }
      }

      .c-table_form {
        &--wrap {
          justify-content: flex-start;
          .u-w_49 {
            width: auto;
            &:first-child {
              margin-right: 25px;
            }
          }
        }

        &--disc {
          display: none;
        }

        &--inputWrap {
          max-width: none;
        }
      }
    }
  }
}
