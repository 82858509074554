@mixin pc($width: 768px) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@mixin tab {
  @media screen and (min-width: 768px) and (max-width: 900px) {
    @content;
  }
  @media screen and (max-width: 767px) {
    @content;
  }
}

@mixin tab02 {
  @media screen and (min-width: 768px) and (max-width: 1050px) {
    @content;
  }
}

@mixin sp($width: 767px) {
  @media screen and (max-width: $width) {
    @content;
  }
}