.p-history {

  &_inner {
    padding: 80px 0;
    background-color: $gray;
    @include sp {
      padding: 60px 0;
    }
  }

  &_cont {
    padding: 60px;
    @include sp {
      padding: 25px 3%;
    }
  }

  &_table {
    th {
      color: $black;
      font-size: 16px;
      font-weight: normal;
      padding: 23px 50px;
      @include sp {
        font-size: 12px;
        padding: 15px 20px;
      }
    }

    a {
      color: $blue;
      font-size: 18px;
      display: inline;
      text-decoration: underline;
      @include sp {
        font-size: 14px;
      }
    }
  }
}