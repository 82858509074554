.p-privacy {
  &_inner {
    max-width: 1040px;
    width: 88.8%;
    margin: 0 auto;
  }

  &_cont {
    &:not(:last-child) {
      margin-bottom: 60px;
      @include sp {
        margin-bottom: 40px;
      }
    }
  }

  &_text {
    font-size: 16px;
    font-weight: 500;
    line-height: 2;
    @include sp {
      font-size: 14px;
    }
  }

  &_title {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 25px;
    text-indent: -0.9em;
    padding-left: 0.9em;
    @include sp {
      font-size: 16px;
      margin-bottom: 15px;
    }
  }

  &_list {
    margin-top: 25px;
    @include sp {
      margin-top: 15px;
    }
    li {
      font-size: 16px;
      font-feature-settings: 'palt';
      font-weight: 500;
      text-indent: -1.6em;
      padding-left: 1.6em;
      @include sp {
        font-size: 14px;
      }
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  &_box {
    padding-left: 20px;
    @include sp {
      padding-left: 10px;
    }
    &:not(:last-of-type) {
      margin-bottom: 40px;
    }

    .p-privacy_text {
      padding-left: 20px;
      @include sp {
        padding-left: 10px;
      }

      &:not(:last-of-type) {
        margin-bottom: 15px;
      }
    }

    .p-privacy_list {
      padding-left: 20px;
      @include sp {
        padding-left: 10px;
      }
    }

    ul {
      margin-bottom: 20px;
    }

    a {
      color: $blue;
      font-size: 16px;
      font-weight: 500;
      display: inline;
      text-decoration: underline;
      @include sp {
        font-size: 14px;
      }
    }
  }

  &_caption {
    font-size: 16px;
    font-weight: bold;
    margin-top: 25px;
    padding-left: 20px;
    line-height: 2;
    @include sp {
      font-size: 14px;
      padding-left: 10px;
    }

    span {
      text-indent: -3em;
      padding-left: 3em;
      display: block;
    }
  }
}