.c-list {
  &_column3 {
    display: flex;
    justify-content: space-between;
    @include sp {
      display: block;
    }

    &--pic {
      margin-bottom: 20px;
      overflow: hidden;
      @include sp {
        margin-bottom: 15px;
      }

      img {
        transition: 0.3s;
      }
    }

    &--heading {
      color: $blue;
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 10px;
      @include sp {
        font-size: 16px;
        margin-bottom: 5px;
      }
    }

    &--text {
      font-size: 16px;
      @include sp {
        font-size: 14px;
      }
    }

    &--item {
      width: 31.7%;
      @include sp {
        width: 100%;
        &:not(:last-child) {
          margin-bottom: 30px;
        }
      }
    }
  }

  &_worry {
    &--item {
      font-size: 16px;
      padding-left: 50px;
      position: relative;
      @include sp {
        font-size: 14px;
        padding-left: 37px;
      }
      &:before {
        content: '';
        width: 33px;
        height: 33px;
        background: url('../img/service/ico_worry.png') no-repeat;
        background-size: contain;
        position: absolute;
        top: -4px;
        left: 0;
        @include sp {
          width: 25px;
          height: 25px;
          top: -3px;
        }
      }
      &:not(:last-child) {
        margin-bottom: 35px;
        @include sp {
          margin-bottom: 20px;
        }
      }
    }
  }

  &_faq {
    &--item {
      border-bottom: 1px solid rgba( $blue, 0.4);
      &:first-child {
        border-top: 1px solid rgba( $blue, 0.4);
      }
    }

    &--question {
      padding: 30px 75px 30px 15px;
      position: relative;
      cursor: pointer;
      position: relative;
      @include sp {
        padding: 20px 45px 20px 3%;
      }
      &:before,
      &:after {
        content: '';
        position: absolute;
        margin: auto 0;
        background-color: $blue;
        top: 0;
        bottom: 0;
        transition: 0.4s;
      }

      &:before {
        width: 26px;
        height: 1px;
        right: 33px;
        @include sp {
          width: 20px;
          right: 14px;
        }
      }

      &:after {
        height: 26px;
        width: 1px;
        right: 46px;
        @include sp {
          height: 20px;
          right: 23px;
        }
      }

      &.is-open {
        &:before {
          opacity: 0;
        }

        &:after {
          transform: rotate(90deg);
        }
      }
    }

    &--answer {
      padding: 30px 15px;
      margin: 0 55px 35px;
      display: none;
      @include sp {
        padding: 20px 3%;
        margin: 0 45px 25px;
      }
    }

    &--text {
      font-size: 16px;
      padding-left: 55px;
      position: relative;
      @include sp {
        font-size: 14px;
        padding-left: 35px;
      }

      span {
        color: $blue;
        font-size: 30px;
        font-weight: bold;
        line-height: 1;
        position: absolute;
        top: -2px;
        left: 0;
        @include sp {
          font-size: 22px;
          top: 0;
        }
      }
    }
  }
}