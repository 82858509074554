.c-box {
  &_gray {
    background-color: $gray;
  }

  &_white {
    background-color: $white;
  }

  &_intro {
    margin-bottom: 90px;
    @include sp {
      margin-bottom: 60px;
    }
    &--read {
      font-size: 22px;
      line-height: 1.5;
      margin-bottom: 25px;
      @include sp {
        font-size: 18px;
        margin-bottom: 20px;
      }
    }

    &--text {
      font-size: 18px;
      line-height: 1.8;
      @include sp {
        font-size: 14px;
        text-align: left;
      }
    }
  }

  &_point {
    padding: 60px 0;
    background-color: $gray;
    @include sp {
      padding: 30px 0;
    }

    &--wrap {
      max-width: 1040px;
      width: 90%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      @include tab {
        display: block;
      }
    }

    &--textBox {
      max-width: 400px;
      width: 38.5%;
      @include tab {
        max-width: none;
        width: 100%;
        margin-bottom: 30px;
      }
    }

    &--read {
      color: $blue;
      font-size: 22px;
      margin-bottom: 25px;
      @include sp {
        font-size: 18px;
        margin-bottom: 15px;
      }
    }

    &--text {
      font-size: 16px;
      line-height: 1.6;
      @include sp {
        font-size: 14px;
      }
    }

    &--pic {
      max-width: 590px;
      width: 56.5%;
      @include tab {
        max-width: none;
        width: 100%;
      }
      img {
        width: 100%;
      }
    }

    &--btn {
      max-width: 366px;
      margin-top: 25px;
    }

    &:nth-child(even) {
      background-color: $white;

      .c-box_point--wrap {
        flex-direction: row-reverse;
      }
    }

    &.white {
      background-color: $white;

      .c-box_point--wrap {
        flex-direction: row-reverse;
      }
    }

    .half {
      .c-box_point {
        &--textBox {
          max-width: 493px;
          width: 47.4%;
          @include tab {
            max-width: none;
            width: 100%;
          }
        }

        &--pic {
          max-width: 496px;
          width: 47.6%;
          @include tab {
            max-width: none;
            width: 100%;
          }
        }
      }
    }

    &.system {
      .c-box_point {
        &--wrap {
          align-items: center;
        }
      }
    }
  }
}