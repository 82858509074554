.c-heading {
  &_container {
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
  }
  &_cmn {
    color: $black;
    font-size: 22px;
    font-weight: 700;
    font-feature-settings: 'palt';
    text-align: center;
    margin-bottom: 25px;
    letter-spacing: 1px;
    @include sp {
      font-size: 20px;
    }

    &--sub {
      color: $blue;
      font-size: 10px;
      font-weight: 500;
      margin-top: 5px;
      display: block;
    }

    &.white {
      color: $white;
      .c-heading_cmn--sub {
        color: $white;
      }
    }
  }

  &_blue {
    color: $blue;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 25px;
    @include sp {
      font-size: 18px;
    }
  }

  &_point {
    color: $blue;
    font-size: 22px;
    padding-bottom: 15px;
    margin-bottom: 25px;
    border-bottom: 1px solid $blue;
    @include sp {
      font-size: 18px;
    }
  }

  &_read {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    @include sp {
      font-size: 18px;
    }
  }
}