.c-btn {
  a,button {
    cursor: pointer;
    display: block;
  }
  &_cmn {
    width: 100%;
    color: $white;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    border: 1px solid $white;
    background: url('../img/common/btn_bg01.png') no-repeat right top;
    padding: 14px 28px 14px 14px;
    position: relative;
    transition: 0.3;
    cursor: pointer;
    display: block;
    &:before {
      content: '';
      width: 6px;
      height: 6px;
      margin: auto 0;
      border: 0px;
      border-top: solid 2px $blue;
      border-right: solid 2px $blue;
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      top: 0;
      bottom: 0;
      right: 5.5%;
      z-index: 2;
    }
    &:after {
      content: '';
      width: 0;
      height: 100%;
      background-color: $white;
      position: absolute;
      top: 0;
      right: 0;
      transition: 0.3s;
      z-index: 1;
    }

    span {
      position: relative;
      z-index: 2;
      transition: 0.1s;
    }

    &:hover {
      color: $blue;
      background-position: -68px;
      opacity: 1;

      &:before {
        border-color: $blue;
      }

      &:after {
        width: 100%;
      }
    }

    &.blue {
      color: $white;
      background-color: $blue;
      background-image: url('../img/common/btn_bg01_blue.png');
      border: none;
      &:before {
        border-color: $white;
      }
      &:after {
        background-color: #1a407b;
      }
    }
  }

  &_map {
    max-width: 172px;
    width: 100%;
    color: $blue;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    padding: 4px 0 6px;
    background-color: $white;
    border: 1px solid $blue;
    transition: 0.3s;
    &:hover {
      color: $white;
      background-color: $blue;
      opacity: 1;
    }
    &.blue {
      color: $white;
      background-color: $blue;
      &:hover {
        color: $blue;
        background-color: $white;
      }
    }
  }

  &_modal {
    color: $white;
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
    max-width: 172px;
    width: 100%;
    height: 31px;
    background-color: $blue;
    border: 1px solid $blue;
    position: relative;
    cursor: pointer;
    transition: 0.3s;
    &:before,
    &:after {
      content: '';
      margin: auto;
      background-color: $white;
      position: absolute;
      top: 0;
      bottom: 0;
      transition: 0.3s;
    }

    &:before {
      width: 10px;
      height: 1px;
      right: 15px;
    }

    &:after {
      width: 1px;
      height: 10px;
      right: 20px;
    }
    &:hover {
      color: $blue;
      background-color: $white;
      &:before,
      &:after {
        background-color: $blue;
      }
    }
  }

  &_pagenation {
    width: 54px;
    height: 52px;
    background-color: $blue;
    border: 1px solid $blue;
    position: relative;
    transition: 0.3s;
    @include sp {
      width: 35px;
      height: 40px;
    }
    @media screen and (max-width: 360px) {
      width: 30px;
      height: 35px;
    }

    span {
      width: 8px;
      height: 8px;
      border: 0px;
      margin: -3px auto 0;
      border-top: solid 2px $white;
      border-right: solid 2px $white;
      -ms-transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
      transform: rotate(-135deg);
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transition: 0.3s;
      @include sp {
        width: 6px;
        height: 6px;
      }
    }

    &.next {
      span {
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    &:hover {
      background-color: $white;
      opacity: 1;

      span {
        border-color: $blue;
      }
    }
  }

  &_back {
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    height: 52px;
    background-color: #e8ecf3;
    cursor: pointer;
    display: block;
  }
}