@charset "UTF-8";


//color
$black: #222;
$gray: #eff1f3;
$white: #fff;
$blue: #255096;
$lightblue: #e8ecf3;

//font-weight
$medium: 500;
$bold: 700;
