.l-header {
  width: 100%;
  background-color: $white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  &_inner {
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    padding: 0 30px;
    @media screen and ( max-width: 1200px ) and (min-width: 1051px) {
      padding: 0 20px;
    }
    @include tab02 {
      padding: 0;
    }
    @include sp {
      padding: 0;
    }
  }

  &_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include tab02 {
      display: block;
    }
    @include sp {
      display: block;
    }
  }

  &_logo {
    max-width: 140px;
    width: 10%;
    img {
      vertical-align: baseline;
    }
    @include tab02 {
      width: 100%;
      margin: 20px 20px 0;
    }
    @include sp {
      max-width: none;
      width: calc( 100% - 67px );
      height: 63px;
      margin: 0;
      padding: 0 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    h1 {
      @include sp {
        max-width: 80px;
        width: 100%;
        display: block;
      }
    }
  }

  &_navi {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include sp {
      display: none;
    }
  }

  &_submenu {
    width: 100%;
    padding-top: 9px;
    position: absolute;
    top: calc( 100% - 9px );
    left: 0;
    z-index: 10;
    opacity: 0;
    pointer-events: none;
    transition: 0.3s;

    &--inner {
      padding: 40px 0;
      background-color: rgba( $blue, 0.8);
    }

    &--list {
      display: flex;
      justify-content: center;
    }

    &--item {
      max-width: 180px;
      &:not(:last-child) {
        margin-right: 1.4%;
      }
    }

    &--pic {
      margin-bottom: 10px;

      img {
        height: auto;
      }
    }

    &--title {
      color: $white;
      font-size: 18px;
      font-weight: 500;
      font-feature-settings: 'palt';
      text-align: center;
      @include tab {
        font-size: 14px;
      }

      span {
        padding-right: 20px;
        position: relative;
        display: inline-block;
        @include tab {
          padding-right: 15px;
        }
        &:after {
          content: '';
          width: 8px;
          height: 8px;
          border: 0px;
          border-top: solid 2px $white;
          border-right: solid 2px $white;
          -ms-transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          position: absolute;
          top: 50%;
          right: 0;
          margin-top: -2px;
        }
      }
    }
  }

  &_menu {
    display: flex;
    @include tab {
      width: 100%;
      justify-content: space-between;
    }
    &--btn {
      position: relative;
      &:before {
        content: '';
        width: 0;
        height: 4px;
        margin: 0 auto;
        background-color: $blue;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        transition: 0.3s;
      }
      &:after {
        content: '';
        width: 1px;
        height: 12px;
        margin: auto 0;
        background-color: #c3c3c3;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
      }
      a {
        font-size: 14px;
        font-weight: 500;
        height: 80px;
        padding: 0 15px;
        display: flex;
        align-items: center;
        line-height: 1;
        @media screen and ( max-width: 1200px ) {
          font-size: 13px;
          padding: 0 10px;
        }
        @include tab {
          font-size: 13px;
        }

        &:hover {
          opacity: 1;
        }
      }

      .arrow {
        padding-right: 20px;
        position: relative;
        &:after {
          content: '';
          width: 8px;
          height: 8px;
          border: 0px;
          border-top: solid 2px $black;
          border-right: solid 2px $black;
          -ms-transform: rotate(135deg);
          -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
          position: absolute;
          top: 50%;
          right: 0;
          margin-top: -5px;
        }
      }

      &.current {
        &:before {
          width: 100%;
        }
      }
    }

    &--item {
      &:first-child {
        .l-header_menu--btn {
          &:after {
            display: none;
          }
        }
      }

      &.is-view {
        .l-header {
          &_menu--btn {
            &:before {
              width: 100%;
            }
          }

          &_submenu {
            opacity: 1;
            pointer-events: auto;
          }
        }
      }

      a {
        &.new {
          position: relative;
          &:before {
            content: 'new';
            font-size: 12px;
            color: red;
            position: absolute;
            top: 17px;
            left: 5px;
            animation: blinking 1s infinite;
            @keyframes blinking {
              0% {opacity: 0;}
              50% {
                opacity: 1;
              }
              100% {opacity: 0;}
            }
          }
        }
      }
    }
  }

  &_company {
    max-width: 183px;
    width: 13%;
    margin-left: 30px;
    @media screen and ( max-width: 1200px ) and (min-width: 1051px ) {
      margin-left: 15px;
    }
    @include tab02 {
      width: 100%;
      position: absolute;
      top: 20px;
      right: 20px;
    }
    @include sp {
      max-width: 105px;
      width: 100%;
      margin: 0;
      position: static;
    }
    img {
      vertical-align: baseline;
    }
  }

  &_border {
    width: 100%;
    height: 9px;
    background-color: #e4e4ec;
    position: relative;
    z-index: -1;
    @include sp {
      height: 6px;
    }
    &:before {
      content: '';
      width: calc( 50% - 700px );
      height: 100%;
      background-color: #525F93;
      position: absolute;
      top: 0;
      left: 0;
      @include sp {
        display: none;
      }
    }

    &--bg {
      max-width: 1400px;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      background: url('../img/common/header_bg01.png') no-repeat left;
      @include sp {
        background-size: 35px auto;
      }
    }
  }

  &_spBtn {
    width: 67px;
    height: 63px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    transition: 0.3s;
    z-index: 102;

    @include pc {
      display: none;
    }

    span {
      width: 30px;
      height: 2px;
      margin: auto;
      background-color: $blue;
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      transition: 0.3s;

      &:first-child {
        transform: translateY( -10px );
      }

      &:last-child {
        transform: translateY( 10px);
      }
    }

    &.is-active {
      background-color: $blue;

      span {
        background-color: $white;

        &:first-child {
          transform: rotate(-45deg);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:last-child {
          transform: rotate(45deg);
        }
      }
    }
  }

  &_spNavi {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 69px;
    left: 100%;
    transition: 0.3s;
    &.is-view {
      left: 0;
    }

    &--colse {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      cursor: pointer;
    }

    &--scroll {
      max-height: calc( 100vh - 69px );
      overflow-y: scroll;
      position: relative;
      z-index: 11;
    }

    &--inner {
      padding: 40px 0;
      background-color: rgba( 26,64,123, 0.9);
    }

    &--container {
      width: 78%;
      margin: 0 auto;
    }

    &--list {
      margin-bottom: 40px;
    }

    &--btn {
      border-bottom: 1px solid $white;

      a {
        color: $white;
        font-size: 14px;
        font-weight: 500;
        padding: 10px 30px 10px 15px;
        position: relative;

        &:after {
          content: '';
          width: 8px;
          height: 8px;
          border: 0px;
          border-top: solid 2px $white;
          border-right: solid 2px $white;
          -ms-transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          position: absolute;
          top: 50%;
          right: 10px;
          margin-top: -4px;
        }
      }

      &.js-dropdown {
        a {
          &:before,
          &:after {
            content: '';
            margin: auto;
            background-color: $white;
            top: 0;
            bottom: 0;
            position: absolute;
            transition: 0.3s;
          }

          &:before {
            width: 12px;
            height: 1px;
            right: 10px;
          }

          &:after {
            width: 1px;
            height: 12px;
            right: 15px;
            transform: rotate(0);
            border: none;
          }
        }
        
        &.is-open {
          a {
            &:before {
              opacity: 0;
            }

            &:after {
              transform: rotate(90deg);
            }
          }
        }
      }
    }

    &--wrap {
      display: flex;
      align-items: center;
    }

    &--main {
      width: 150px;

      &.new {
        &:after {
          content: 'new';
          color: red;
          font-size: 12px;
          margin-left: 10px;
          animation: blinking 1s infinite;
            @keyframes blinking {
              0% {opacity: 0;}
              50% {
                opacity: 1;
              }
              100% {opacity: 0;}
            }
        }
      }
    }

    &--sub {
      font-size: 10px;
      width: calc( 100% - 150px );
    }

    &--subList {
      padding-left: 15px;
      display: none;
      a {
        font-size: 12px;
      }
    }

    .c-btn {
      a {
        border: 1px solid $white;
        background-image: url('../img/common/btn_bg01.png');

        &:before {
          border-color: $blue;
        }

        &:after {
          background-color: $white;
        }

        &:hover {
          color: $blue;
        }
      }
    }
  }
}