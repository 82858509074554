.p-contact {
  &_text {
    font-size: 16px;
    font-weight: 500;
    line-height: 2;
    margin-bottom: 60px;
    @include sp {
      font-size: 14px;
      text-align: left;
      margin-bottom: 40px;
    }

    .tab {
      @include sp {
        display: none;
      }
    }
  }

  &_flow {
    display: flex;
    border: 1px solid $blue;
    overflow: hidden;
    margin-bottom: 45px;
    @include sp {
      margin-bottom: 30px;
    }

    span {
      color: $blue;
      font-size: 18px;
      font-weight: 700;
      text-align: center;
      padding: 11px 0 13px;
      display: block;
      transform: skew(-30deg, 0deg);
      -webkit-transform: skew(-30deg, 0deg);
      @include sp {
        font-size: 14px;
      }
    }

    .current {
      position: relative;
      &:before {
        content: '';
        width: calc( 100% + 15px );
        height: 100%;
        background-color: $blue;
        position: absolute;
        top: 0;
        left: -15px;
      }
      span {
        color: $white;
      }
    }

    li {
      width: calc( 100% / 3 );
      transform: skew(30deg, 0deg);
      -webkit-transform: skew(30deg, 0deg);
      &:not(:last-child) {
        border-right: 1px solid $blue;
      }

      &:nth-child(2) {
        &.current {
          background-color: $blue;
  
          &:before {
            display: none;
          }
        }
      }
  
      &:last-child {
        &.current {
          &:before {
            left: auto;
            right: -15px;
          }
        }
      }
    }
  }

  &_form {
    &--text {
      font-size: 16px;
      margin-bottom: 40px;
      @include sp {
        font-size: 14px;
        margin-bottom: 30px;
      }

      span {
        margin-right: 5px;
      }
    }
  }

  &_required {
    color: $white;
    font-size: 14px;
    font-weight: 400;
    width: 54px;
    padding: 1px 0 2px;
    background-color: $blue;
    text-align: center;
    display: inline-block;
  }

  &_privacy {
    margin-bottom: 40px;

    .mwform-checkbox-field-text {
      font-size: 16px;
      margin-right: 0;
    }
  }

  &_btn {
    max-width: 330px;
    width: 100%;
    margin: 0 auto;
  }

  &_btnWrap {
    display: flex;
    justify-content: center;
    @include sp {
      display: block;
    }

    .p-contact_btn {
      margin: 0;
      @include sp {
        margin: 0 auto;
      }
      &:not(:last-child) {
        margin-right: 25px;
        @include sp {
          margin: 0 auto 20px;
        }
      }
    }
  }

  &_thanks {
    padding: 60px 0;
    background-color: $lightblue;
    @include sp {
      padding: 40px 20px;
    }

    &--read {
      margin-bottom: 25px;
      @include sp {
        font-feature-settings: 'palt';
        margin-bottom: 15px;
      }
    }

    &--text {
      line-height: 2;
      @include sp {
        font-size: 14px;
        text-align: left;
      }
    }
  }

  .c-table_form {
    &.confirm {
      .p-contact_required {
        display: none;
      }
    }
  }

  .mw_wp_form_confirm {
    .p-contact_privacy {
      display: none;
    }
  }
}