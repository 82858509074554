.p-reason {
  &_inner {
    padding: 80px 0;
    margin-bottom: 80px;
    background-color: $gray;
    @include sp {
      padding: 40px 0;
      margin-bottom: 40px;
    }
  }

  &_text {
    font-size: 16px;
    text-align: center;
    margin-bottom: 40px;
    @include sp {
      font-size: 14px;
      margin-bottom: 30px;
      text-align: left;
    }
  }

  &_list {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    @include sp {
      display: block;
      margin-bottom: 30px;
    }

    &--pic {
      width: 100%;
      padding-bottom: 55.9%;
      position: relative;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate( -50%, -50%);
        transition: 0.3s;
      }
    }

    &--box {
      padding: 25px;
      @include tab {
        padding: 15px;
      }
    }

    &--heading {
      color: $blue;
      font-size: 22px;
      font-weight: 700;
      font-feature-settings: 'palt';
      margin-bottom: 15px;
      @include tab {
        font-size: 18px;
      }
    }

    &--text {
      font-size: 16px;
      @include tab {
        font-size: 14px;
      }
    }

    &--item {
      width: 31.7%;
      background-color: $white;
      @include sp {
        width: 100%;
        margin-bottom: 30px;
      }

      a {
        display: block;

        &:hover {
          opacity: 1;

          .p-reason_list--pic img {
            transform: translate( -50%, -50%) scale(1.1);
          }
        }
      }
    }
  }

  &_btn {
    max-width: 330px;
    width: 100%;
    margin: 0 auto;
  }
}