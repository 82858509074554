.c-localLink {
  margin-bottom: 80px;
  @include sp {
    margin-bottom: 40px;
  }
  &_list {
    display: flex;
    justify-content: space-between;
    @include tab {
      flex-wrap: wrap;
    }
    @include sp {
      flex-wrap: wrap;
    }
  }

  &_item {
    width: 15.5%;
    @include tab {
      width: 32%;
    }
    @include sp {
      width: 48%;
    }

    &:nth-of-type(-n+3) {
      @include tab {
        margin-bottom: 15px;
      }
    }
    &:not(:nth-last-child(-n+2)) {
      @include sp {
        margin-bottom: 15px;
      }
    }

    a {
      color: $blue;
      font-size: 14px;
      font-weight: 500;
      width: 100%;
      height: 100%;
      padding: 10px 0 18px;
      border: 1px solid $blue;
      position: relative;
      transition: 0.3s;
      display: flex;
      align-items: center;
      justify-content: center;

      &:after {
        content: '';
        width: 7px;
        height: 7px;
        margin: auto;
        border: 0px;
        border-top: solid 1px $blue;
        border-right: solid 1px $blue;
        -ms-transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
        position: absolute;
        bottom: 10px;
        right: 0;
        left: 0;
        transition: 0.3s;
      }

      span {
        text-align: center;
      }

      &[target="_blank"] {
        &:after {
          display: none;
        }

        &:before {
          content: '';
          width: 15px;
          height: 12px;
          margin: 0 auto;
          background: url('../img/common/ico_link01.svg') no-repeat;
          background-size: contain;
          position: absolute;
          right: 0;
          left: 0;
          bottom: 5px;
          transition: 0.3s;
        }
      }

      &:hover {
        color: $white;
        background-color: $blue;
        opacity: 1;

        &:after {
          border-color: $white;
        }

        &:before {
          background-image: url('../img/common/ico_link01_on.svg');
        }
      }
    }
  }
}