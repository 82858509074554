.c-modal {
  &_cont {
    max-width: 1160px;
    width: 83%;
    height: 90vh;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    overflow-y: scroll;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s;
    &.is-view {
      opacity: 1;
      pointer-events: auto;
    }
    &--inner {
      padding: 60px 6.5%;
      background-color: $white;
      position: relative;
      @include sp {
        padding: 50px 4% 30px;
      }
      &:before {
        content: '';
        width: 52.3%;
        height: 100%;
        background: url('../img/common/modal_bg01.png') no-repeat bottom right;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        @include sp {
          width: 75%;
          background-size: 100% 100%;
        }
      }
    }
  }

  &_close {
    width: 4.3%;
    position: absolute;
    top: 3.4%;
    right: 40px;
    cursor: pointer;
    @include sp {
      width: 20px;
      top: 10px;
      right: 15px;
    }
  }

  &_scroll {
    position: relative;
    z-index: 2;
  }

  &_top {
    display: flex;
    justify-content: space-between;

    &--img {
      max-width: 486px;
      width: 47.6%;
    }
  }

  &_overlay {
    width: 100%;
    height: 100%;
    background-color: rgba($blue, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 998;
    cursor: pointer;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
    &.is-view {
      opacity: 1;
      pointer-events: auto;
    }
  }
}