.p-works {
  &_list {
    margin-bottom: 80px;
    @include sp {
      margin-bottom: 40px;
    }
  }
  &_box {
    border-bottom: 1px solid rgba($blue, 0.4);
    &:first-child {
      border-top: 1px solid rgba($blue, 0.4);
    }

    a {
      padding: 40px 0;
      @include sp {
        padding: 25px 0;
      }
    }
  }

  &_wrap {
    display: flex;
    justify-content: space-between;
    @include sp {
      display: block;
    }
  }

  &_pic {
    max-width: 350px;
    width: 30%;
    overflow: hidden;
    @include sp {
      max-width: none;
      width: 100%;
      margin-bottom: 25px;
    }

    &--inner {
      width: 100%;
      padding-bottom: 60%;
      background-size: cover;
      background-position: center center;
      position: relative;
      transition: 0.3s;
    }
  }

  &_textBox {
    max-width: 770px;
    width: 67%;
    @include sp {
      max-width: none;
      width: 100%;
    }
  }

  &_date {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
    @include sp {
      font-size: 12px;
      margin-bottom: 5px;
    }
  }

  &_title {
    color: $blue;
    font-size: 22px;
    margin-bottom: 25px;
    @include sp {
      font-size: 16px;
      margin-bottom: 15px;
    }
  }

  &_text {
    font-size: 18px;
    line-height: 1.7;
    @include sp {
      font-size: 14px;
    }

    .more {
      color: $blue;
      margin-left: 25px;
      text-decoration: underline;
    }
  }

  &_box a:hover {
    opacity: 1;
    .p-works {
      &_pic {
        &--inner {
          transform: scale(1.1);
        }
      }
    }
  }
}