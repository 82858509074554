.c-column {
  &_two {
    display: flex;
    justify-content: space-between;
    @include sp {
      display: block;
    }

    &--box {
      width: 47.4%;
      @include sp {
        width: 100%;
      }
    }
  }
}