.p-grouppage {
  .p-network_hdqrs--textBox {
    h3 {
      font-size: 18px;
    }
  }
  &_office {
    &--top {
      margin-bottom: 20px;
      @include sp {
        margin-bottom: 15px;
      }
    }
    &--text {
      margin-bottom: 25px;
      @include sp {
        margin-bottom: 20px;
      }
    }
    h3 {
      color: $black;
      font-size: 18px;
      padding: 0 !important;
      margin-bottom: 10px;
      position: static;
    }
  }

  .c-btn_cmn {
    @media screen and (max-width: 1200px ) {
      font-size: 13px;
    }
  }
}