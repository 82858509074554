.p-service {
  &_inner {
    padding: 80px 0 40px;
    @include sp {
      padding: 40px 0;
    }
  }

  &_text {
    font-size: 16px;
    text-align: center;
    margin-bottom: 40px;
    @include sp {
      font-size: 14px;
      width: 88.8%;
      margin: 0 auto 30px;
      text-align: left;
    }

    .tab {
      @include sp {
        display: none;
      }
    }
  }

  &_list {
    display: flex;
    flex-wrap: wrap;
    @include sp {
      display: block;
    }

    a {
      &:hover {
        opacity: 1;
      }
    }

    &--top {
      width: 100%;
      padding-bottom: 60%;
      margin-bottom: 15px;
      position: relative;
      overflow: hidden;
    }

    &--pic {
      width: 100%;
      height: 100%;
      transition: 0.3s;
      position: absolute;
      top: 0;
      left: 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &--heading {
      color: $white;
      font-size: 20px;
      font-weight: 500;
      width: 100%;
      padding: 10px 15px;
      background-color: rgba(0,0,0,0.5);
      display: block;
      position: absolute;
      bottom: -24px;
      left: 0;
      transition: 0.3s;
      @include sp {
        font-size: 18px;
      }

      span {
        font-size: 12px;
        margin-top: 3px;
        display: block;
      }
    }

    &--text {
      font-size: 16px;
      padding: 0 15px;
      text-align: justify;
    }

    &--item {
      width: 33.33%;
      margin-bottom: 40px;
      @include sp {
        width: 100%;
        margin-bottom: 30px;
      }
      &:not(:nth-child(3n)) {
        border-right: 1px solid $white;
        @include sp {
          border: none;
        }
      }

      &:hover {
        .p-service_list--heading {
          bottom: 0;
        }

        .p-service_list--pic {
          transform: scale(1.1);
        }
      }
    }
  }
}