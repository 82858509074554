.p-group {
  &_inner {
    padding: 80px 0;
    background-color: $gray;
    @include sp {
      padding: 40px 0;
    }
  }

  &_list {
    display: flex;
    justify-content: center;
    @include sp {
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &--item {
      width: 23.4%;
      @include tab {
        width: 32%;
      }
      @include sp {
        width: 48%;
        margin-bottom: 10px;
      }

      &:not(:nth-child(3n)) {
        margin-right: 3.2%;
        @include tab {
          margin-right: 2%;
        }
        @include sp {
          margin-right: 0;
        }
      }

      img {
        width: 100%;
      }
    }

    &--ttl {
      font-size: 14px;
      font-weight: bold;
      margin-top: 10px;
      @include tab {
        font-size: 12px;
      }
      @include sp {
        margin-top: 5px;
      }
    }
  }
}