.p-case {
  &_inner {
    padding: 80px 0;
    @include sp {
      padding: 60px 0;
    }
  }

  &_text {
    font-size: 16px;
    text-align: center;
    margin-bottom: 40px;
    @include sp {
      font-size: 14px;
      text-align: left;
      margin-bottom: 30px;
    }
  }

  &_list {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    @include sp {
      margin-bottom: 30px;
      display: block;
    }

    &--pic {
      margin-bottom: 20px;
      overflow: hidden;
      @include sp {
        margin-bottom: 15px;
      }

      img {
        transition: 0.3s;
        height: auto;
      }
    }

    &--heading {
      color: $blue;
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 10px;
      @include sp {
        font-size: 16px;
        margin-bottom: 5px;
      }
    }

    &--text {
      font-size: 16px;
      @include sp {
        font-size: 14px;
      }
    }

    &--item {
      width: 31.7%;
      @include sp {
        width: 100%;
        margin-bottom: 30px;
      }
      &:hover {
        .p-case_list--pic img {
          transform: scale(1.1);
        }
      }

      a {
        &:hover {
          opacity: 1;
        }
      }
    }
  }

  &_btn {
    max-width: 365px;
    width: 100%;
    margin: 0 auto;
  }
}