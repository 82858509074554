.p-servicepage {

  &_detail {
    margin-bottom: 40px;
  }

  &_intro {
    &--read {
      @include sp {
        text-align: left;
      }
    }
  }


  &_worry {
    margin-bottom: 80px;
    @include sp {
      margin-bottom: 60px;
    }
    &--box {
      max-width: 793px;
      width: 100%;
      margin: 0 auto;
      padding: 40px;
      @include sp {
        padding: 30px 20px;
      }
    }

    &--read {
      margin-bottom: 40px;
      @include sp {
        margin-bottom: 30px;
      }
    }

    &--list {
      display: flex;
      justify-content: center;
    }
  }

  &_point {
    margin-bottom: 80px;
    @include sp {
      margin-bottom: 60px;
    }
    &--text {
      margin-bottom: 15px;
    }

    &--read {
      margin-bottom: 40px;
    }
  }

  &_faq {
    margin-bottom: 80px;
    @include sp {
      margin-bottom: 60px;
    }
  }

  &_service {
    padding: 0;
  }
}