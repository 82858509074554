.p-mv {
  position: relative;
  overflow: hidden;
  @include sp {
    overflow: visible;
    margin-bottom: 60px;
  }

  .slick-dotted.slick-slider {
    margin-bottom: 0 !important;
  }

  &_slide {
    &--bg {
      width: 100%;
      height: auto;
      padding-bottom: 48.21%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      @media screen and (min-width: 900px) and (max-width: 1200px) {
        height: 674px;
        padding: 0;
      }
      @include tab {
        height: 580px;
      }
      @include sp {
        height: auto;
        padding-bottom: 151.7%;
      }
    }

    &--item {
      &.item02 {
        .p-mv_slide--bg {
          background-position: 75% center;
        }
      }
    }
  }

  &_contWrap {
    width: 100%;
    display: flex;
    align-items: flex-end;
    position: absolute;
    left: 0;
    bottom: 0;
    @include sp {
      display: block;
      position: relative;
    }
  }

  &_left {
    min-width: 560px;
    width: 50%;
    position: relative;
    @include tab {
      min-width: 530px;
    }
    @include sp {
      min-width: auto;
      width: 100%;
      padding: 20px 0 30px;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    &--box {
      width: calc(100% - 182px);
      height: 320px;
      background-color: rgba(37,80,150,0.9);
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      padding-right: 4%;
      @include tab {
        width: calc( 100% - 170px );
        height: 280px;
      }
      @include sp {
        width: 100%;
        height: auto;
        padding: 0;
        background-color: transparent;
      }
    }

    &--bgCont {
      width: 182px;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      overflow: hidden;
      @include tab {
        width: 170px;
      }
      @include sp {
        width: 100%;
      }
    }

    &--bg {
      width: 100%;
      height: 100%;
      position: relative;
      &:before {
        content: '';
        width: 115%;
        height: 100%;
        background-color: rgba(37,80,150,.9);
        position: absolute;
        top: 0;
        right: 89.5px;
        transform: skew(30deg, 0deg);
        @include sp {
          width: 110%;
          right: auto;
          left: -93px;
        }
        @media screen and (max-width: 360px) {
          width: 120%;
        }
      }
    }

    &--wrap {
      max-width: 365px;
      width: 90%;
      @include sp {
        max-width: none;
        width: 89%;
        margin: 0 auto;
        position: relative;
        z-index: 1;
      }

      .c-btn {
        @include sp {
          max-width: 295px;
          width: 100%;
          margin: 0 auto;
        }
      }
    }
  }

  &_right {
    width: 50%;
    height: 90px;
    background-color: rgba(26,64,123,0.9);
    position: relative;
    display: flex;
    align-items: center;
    @include sp {
      width: 68%;
      height: 60px;
      position: absolute;
      top: 100%;
      right: 0;
    }

    &--bgCont {
      width: 50px;
      height: 100%;
      position: absolute;
      right: 100%;
      top: 0;
      overflow: hidden;
      @include tab {
        width: 60px;
      }
      @include sp {
        width: 35px;
      }
    }

    &--bg {
      width: 100%;
      height: 100%;
      position: relative;
      &:before {
        content: '';
        width: 100%;
        height: 100%;
        background-color: rgba(26,64,123,0.9);
        position: absolute;
        top: 0;
        left: 25.5px;
        transform: skew(30deg, 0deg);
        @include sp {
          width: 70px;
          left: 16.5px;
        }
      }
    }
  }

  &_read {
    color: $white;
    font-size: 26px;
    font-weight: 500;
    line-height: 1.7;
    margin-bottom: 15px;
    @include tab {
      font-size: 22px;
    }
    @include sp {
      font-feature-settings: 'palt';
      min-width: 205px;
      width: 63.7%;
      margin-bottom: 10px;
    }
    @media screen and (max-width: 360px) {
      font-size: 20px;
    }
  }

  &_text {
    color: $white;
    font-size: 16px;
    line-height: 1.7;
    margin-bottom: 15px;
    @include tab {
      font-size: 14px;
    }
    @include sp {
      min-width: 205px;
      width: 63.7%;
      margin-bottom: 10px;
    }
  }

  &_bar {
    max-width: 450px;
    width: 100%;
    padding: 0 3% 0 10%;
    @include tab {
      padding: 0 5% 0 0;
    }
    @include sp {
    }

    &--list {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    li {
      width: 31%;
      padding: 10px 0;
      cursor: pointer;
      
      button {
        font-size: 0;
        line-height: 0;
        width: 100%;
        height: 2px;
        background-color: rgba(255,255,255,0.6);
        display: block;
      }

      &.slick-active button {
        position: relative;
        &:before {
          content: '';
          width: 0;
          height: 100%;
          background-color: $white;
          position: absolute;
          top: 0;
          left: 0;
          animation: bar 6s linear;

          @keyframes bar {
            0% {
              width: 0;
            }
            100% {
              width: 100%;
            }
          }
        }
      }
    }
  }
}