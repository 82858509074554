.c-pagenation {
  &_wrap {
    display: flex;
    justify-content: center;
  }

  a, span {
    color: $blue;
    font-size: 14px;
    line-height: 1px;
    width: 54px;
    height: 52px;
    margin: 0 5px;
    border: 1px solid $blue;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    @include sp {
      font-size: 13px;
      width: 35px;
      height: 40px;
    }
    @media screen and (max-width: 360px) {
      width: 30px;
      height: 35px;
    }
    &:hover {
      color: $white;
      background-color: $blue;
      opacity: 1;
    }

    &.current {
      color: $white;
      font-weight: bold;
      background-color: $blue;
    }

    &.prev,
    &.next {
      font-size: 0;
      background-color: $blue;
      position: relative;
      &:before {
        content: '';
        width: 8px;
        height: 8px;
        border: 0px;
        margin: -3px auto 0;
        border-top: solid 2px $white;
        border-right: solid 2px $white;
        -ms-transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        transform: rotate(-135deg);
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transition: 0.3s;
        @include sp {
          width: 6px;
          height: 6px;
        }
      }
      &:hover {
        background-color: $white;
        &:before {
          border-color: $blue;
        }
      }
    }

    &.prev {
      margin-right: 25px;
      @include sp {
        margin-right: 10px;
      }
    }

    &.next {
      margin-left: 25px;
      @include sp {
        margin-left: 10px;
      }
      &:before {
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
}