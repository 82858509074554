.l-footer {
  &_contact {
    padding: 5.7% 0;
    position: relative;
    @include sp {
      padding: 40px 0;
    }

    &.sub {
      padding-top: 0;
    }

    &--box {
      position: relative;
      z-index: 3;
    }

    .c-heading_cmn {
      @include sp {
        margin-bottom: 15px;
      }
    }

    &--text {
      color: $white;
      font-size: 28px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 2.85%;
      @include sp {
        font-size: 20px;
      }
    }

    &--btn {
      max-width: 365px;
      width: 96%;
      margin: 0 auto;
    }
  }
  &_inner {
    padding: 60px 0;
    border-top: 1px solid $blue;
  }

  &_wrap {
    display: flex;
    justify-content: space-between;
    @include tab {
      display: block;
    }
  }

  &_left {
    @include tab {
      display: flex;
      justify-content: space-between;
    }
    @include sp {
      margin-bottom: 30px;
      display: block;
      text-align: center;
    }
  }

  &_logo {
    width: 186px;
    height: 51px;
    margin-bottom: 50px;
    display: block;
    @include tab {
      margin-bottom: 30px;
    }
    @include sp {
      margin: 0 auto 30px;
    }

    img {
      width: 100%;
    }
  }

  &_company {
    &--logo {
      width: 183px;
      margin-bottom: 10px;
      display: block;
      @include sp {
        margin: 0 auto 10px;
      }
    }

    &--address {
      font-size: 14px;
    }
  }

  &_right {
    &--wrap {
      display: flex;
      @include sp {
        display: block;
      }
    }
  }

  &_navi {
    &:not(:last-child) {
      margin-right: 40px;
      @include sp {
        margin: 0;
      }
    }

    a, h2 {
      color: $blue;
      @include sp {
        padding: 10px 15px;
        border-bottom: 1px solid $blue;
        position: relative;
      }
    }

    a {
      &:after {
        @include sp {
          content: '';
          width: 6px;
          height: 6px;
          margin: auto ;
          border: 0px;
          border-top: solid 2px $blue;
          border-right: solid 2px $blue;
          -ms-transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          position: absolute;
          top: 0;
          bottom: 0;
          right: 10px;
        }
      }
    }

    h2 {
      @include sp {
        position: relative;
        cursor: pointer;
        &:before,
        &:after {
          content: '';
          margin: auto;
          background-color: $blue;
          position: absolute;
          top: 0;
          bottom: 0;
          transition: 0.3s;
        }
        &:before {
          width: 10px;
          height: 1px;
          right: 10px;
        }
        &:after {
          width: 1px;
          height: 10px;
          right: 15px;
        }
  
        &.is-open {
          &:before {
            opacity: 0;
          }
  
          &:after {
            transform: rotate(90deg);
          }
        }
      }
    }

    &--item {
      &:not(:last-child) {
        margin-bottom: 10px;
        @include sp {
          margin-bottom: 0 !important;
        }
      }
      >a, h2 {
        font-size: 16px;
        font-weight: 700;
        a {
          font-size: 16px;
          font-weight: 700;
        }
      }

      a {
        &[target="_blank"] {
          padding-right: 27px;
          position: relative;
          &:before {
            content: '';
            width: 17px;
            height: 13px;
            margin: auto 0;
            background: url('../img/common/ico_link01.svg') no-repeat;
            background-size: contain;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            @include sp {
              right: 10px;
            }
          }

          &:after {
            @include sp {
              display: none;
            }
          }
        }
      }
    }
  }

  &_subNavi {
    padding-left: 18px;
    @include sp {
      padding-left: 15px;
      display: none;
    }
    li {
      margin-top: 15px;
      @include sp {
        margin: 0;
      }
    }
    a {
      font-size: 14px;
    }
  }

  &_bottom {
    padding: 15px 0;
    background-color: $blue;

    &--inner {
      max-width: 1160px;
      width: 96%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      @include tab {
        display: block;
      }
      @include sp {
        display: block;
      }
    }

    &--list {
      display: flex;
      @include tab {
        margin-bottom: 10px;
        justify-content: center;
      }
      @include sp {
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 10px;
      }

      li {
        margin-right: 25px;
        @include sp {
          width: 48%;
          margin-right: 0;
        }
      }

      a {
        color: $white;
        font-size: 14px;

        &[target='_blank'] {
          padding-right: 27px;
          position: relative;
          &:after {
            content: "";
            width: 17px;
            height: 13px;
            margin: auto 0;
            background: url('../img/common/ico_link01_on.svg') no-repeat;
            background-size: contain;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
          }
        }
      }
    }
  }

  &_copyright {
    color:$white;
    font-size: 12px;
    text-align: center;
  }
}